import { Component, OnInit, ViewChild } from '@angular/core';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { GeoRolsService, AlertService, UserService } from 'src/app/services';
import { GeoRol, GeoConfig, UserAssignWithName } from 'src/app/models';

@Component({
  selector: 'app-geo-rols',
  templateUrl: './geo-rols.component.html',
  styleUrls: ['./geo-rols.component.css']
})
export class GeoRolsComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('ngbTabset') tabset;
  rolModal: NgbModalRef;

  geoRols: GeoRol[];
  geoRolInEdition: GeoRol;
  geoRolToAssign: number;
  userEditingGeoRol: number;

  allGeoConfigs: GeoConfig[];
  availableGeoConfigs: GeoConfig[];
  assignedGeoConfigs: GeoConfig[];
  geoRolFilter: number;
  geoRolName: string;
  users: UserAssignWithName[];

  constructor(private modalService: NgbModal,
    private geoRolsService: GeoRolsService,
    private alertService: AlertService,
    private userService: UserService) { }

  ngOnInit() {
    this.getGeoRols();
    this.getGeoConfigs(0);
    this.getUserAssignsWithName();
  }

  getGeoRols() {
    this.blockUI.start();

    this.geoRolsService.GetGeoRols().subscribe(response => {
      this.blockUI.stop();
      if (response.result) {
        this.geoRols = response.Georols;
        this.geoRolFilter = this.geoRols[0].Id;
      } else {
        console.log(response.errorInfo.Message);
      }
    }, err => {
      this.blockUI.stop();
      console.log(err);
    });
  }

  onTabChange(event) {
    if (event.nextId === 'tabCrud') this.getGeoConfigs(this.geoRolFilter, false);
    else this.geoRolInEdition = null;
  }

  onClickAddGeoPerms(geoRol: GeoRol) {
    this.tabset.activeId = 'tabCrud';
    this.geoRolFilter = geoRol.Id;
    this.geoRolInEdition = { ...geoRol };

    this.getGeoConfigs(this.geoRolInEdition.Id, false);
  }

  onClickEditRol(geoRol: GeoRol, modalContent: any) {
    this.geoRolInEdition = { ...geoRol };
    this.geoRolName = this.geoRolInEdition.Name;
    this.rolModal = this.modalService.open(modalContent, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
  }

  onClickSaveGeoRol() {
    if (!this.geoRolInEdition)
      this.geoRolInEdition = {
        Id: 0,
        Name: this.geoRolName
      };
    else this.geoRolInEdition.Name = this.geoRolName;

    this.blockUI.start();
    this.geoRolsService.handlePostOrPutGeoRol(this.geoRolInEdition).subscribe(response => {
      this.geoRolInEdition = null;
      this.geoRolName = null;
      this.blockUI.stop();

      if (response.result) {
        this.getGeoRols();
        this.alertService.successInfoAlert('Proceso finalizado exitosamente');
      }
      else this.alertService.errorAlert(response.errorInfo.Message);
    }), err => {
      this.blockUI.stop();
      this.alertService.errorAlert(err);
    };
    this.onClickDismissModal();
  }

  onClickDismissModal() {
    this.rolModal.close();
    this.geoRolName = null;
    this.geoRolInEdition = null;
  }

  getGeoConfigs(geoRolId: number, loadingAllGeoConfigs = true) {
    this.blockUI.start();
    this.geoRolsService.GetGeoConfigs(geoRolId).subscribe(response => {
      this.blockUI.stop();
      if (response.result) {
        if (loadingAllGeoConfigs) this.allGeoConfigs = response.GeoConfigs;
        else {
          this.assignedGeoConfigs = response.GeoConfigs;
          this.getAvailableGeoConfigs();
        }
      } else {
        this.assignedGeoConfigs = [];
        this.getAvailableGeoConfigs();
        console.log(response.errorInfo.Message);
      }

    }, err => {
      this.blockUI.stop();
      console.log(err);
    });
  }

  getAvailableGeoConfigs() {
    if (!this.assignedGeoConfigs || this.assignedGeoConfigs.length === 0) this.availableGeoConfigs = this.allGeoConfigs.slice();
    else this.availableGeoConfigs = this.allGeoConfigs.filter(x => !this.assignedGeoConfigs.some(y => y.Id === x.Id));
  }

  unassignGeoConfig(index: number) {
    let removedGeoConfigs = this.assignedGeoConfigs.splice(index, 1);

    this.availableGeoConfigs.push(removedGeoConfigs[0]);
  }

  assignGeoConfig(index: number) {
    let removedGeoConfigs = this.availableGeoConfigs.splice(index, 1);

    this.assignedGeoConfigs.push(removedGeoConfigs[0]);
  }

  assignAllGeoConfigs() {
    this.availableGeoConfigs = [];
    this.assignedGeoConfigs = this.allGeoConfigs.slice();
  }

  removeAllGeoConfigs() {
    this.assignedGeoConfigs = [];
    this.availableGeoConfigs = this.allGeoConfigs.slice();
  }

  onClickSaveGeoConfigs() {
    this.blockUI.start();
    this.geoRolsService.PostGeoConfigs(this.geoRolInEdition ? this.geoRolInEdition.Id : this.geoRolFilter, this.assignedGeoConfigs).subscribe(response => {
      this.blockUI.stop();
      if (response.result) {
        this.alertService.successInfoAlert('Proceso finalizado exitosamente');
      } else {
        this.alertService.errorAlert(`Ocurrió un error guardando los permisos geo ${response.errorInfo.Message}`);
      }
    }, err => {
      this.blockUI.stop();
      this.alertService.errorAlert(err);
    });
  }

  onClickNewGeoRol(modalContent: any) {
    this.rolModal = this.modalService.open(modalContent, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
  }

  async onClickDeleteGeoRol(geoRolId: number, index: number) {
    let result = await this.alertService.confirmationAlert(`¿Eliminar rol geo?`, '¿Estas seguro que deseas eliminar el rol geo?', 'Eliminar');

    if (result) {
      this.blockUI.start();

      this.geoRolsService.deleteGeoRol(geoRolId).subscribe(response => {
        this.blockUI.stop();

        if (response.result) {
          this.geoRols.splice(index, 1);
          this.alertService.successInfoAlert('Proceso finalizado exitosamente');
        } else {
          this.alertService.errorAlert(`Ocurrió un error eliminando el rol geo ${response.errorInfo.Message}`);
        }
      }, err => {
        this.blockUI.stop();
        this.alertService.errorAlert(err);
      });
    }
  }

  getUserAssignsWithName() {
    this.userService.GetUserAssignsWithName().subscribe(response => {
      if (response.result) {
        this.users = response.Users;
      } else {
        this.users = [];
        console.log(response.errorInfo.Message);
      }
    }, err => {
      console.log(err);
    });
  }

  getGeoRolNameById(geoRolId: number) {
    let geoRol = this.geoRols.find(x => x.Id === geoRolId);

    if (!geoRol) return 'Sin asignación';

    return geoRol.Name;
  }


  onClickUserGeoRol(user: UserAssignWithName, assignGeoRol: any) {
    this.geoRolToAssign = user.GeoRolId;
    this.userEditingGeoRol = user.Id;
    this.rolModal = this.modalService.open(assignGeoRol, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
  }

  onClickPutUserGeoRol() {
    this.onClickDismissModal();
    this.blockUI.start();

    this.userService.PutUserGeoRol(this.userEditingGeoRol, this.geoRolToAssign).subscribe(response => {
      this.blockUI.stop();
      this.geoRolToAssign = null;
      this.userEditingGeoRol = null;
      if (response.result) {
        this.alertService.successInfoAlert('Proceso finalizado eexitosamente');
        this.getUserAssignsWithName();
      } else {
        this.alertService.successInfoAlert(`Ocurrió un error editando el rol geo del usuario ${response.errorInfo.Message}`);
      }
    }, err => {
      this.blockUI.stop();
      this.geoRolToAssign = null;
      this.userEditingGeoRol = null;
      this.alertService.errorAlert(err);
    });
  }
}
