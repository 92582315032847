import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';

// MODELOS
import { Globals } from './../../../globals';
import { UsersResponse } from './../../../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { AlertService, UserService } from './../../../services/index';

@Component({
  selector: 'app-users-view',
  templateUrl: './users-view.component.html',
  styleUrls: ['./users-view.component.css']
})
export class UsersViewComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  userList: any[] = []; // lista de usuarios ya creados
  title = 'Usuarios';

  // PERMISOS
  G_CreateUser: boolean; // permiso para la creacion de rutas
  G_EditUser: boolean; // permiso para la edicion de rutas

  constructor(private alertService: AlertService,
    private router: Router,
    private sUsers: UserService,
    private globals: Globals,
  ) {
    this.G_CreateUser = this.globals.generalWebPermList.some(x => x.Name === 'G_CreateUser');
    this.G_EditUser = this.globals.generalWebPermList.some(x => x.Name === 'G_EditUser');
  }

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.chargeUser();
  }

  chargeUser() {
    this.blockUI.start();
    this.sUsers.getUsers()
      .subscribe((data: UsersResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.userList = data.userList;
        } else {
          this.alertService.warningAlert(`${data.errorInfo.Message}`);
        }
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorAlert(`${error}`);
      });
  }

  confPage(id) {
    this.router.navigate(['userConf/' + id]);
  }
}
