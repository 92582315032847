import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// MODELOS
import { AppConstants, GetBPResponse, CardGroupsResponse, SubTipoResponse, BusinessPartnersMobileResponse, BusinessPartnersModel, Location } from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService } from './storage.service';
import { BaseResponse, LocationsResponse } from '../models/responses';

@Injectable({
  providedIn: 'root'
})
export class BussinesPartnersService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  // metodo que obtiene los clientes de la compannia
  GetAllCustomers(Balance: boolean) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<GetBPResponse>(`${AppConstants.apiUrl}api/BusinessPartners/GetAllCustomers?userMappId=${TOKEN.userMappId}&Balance=${Balance}`,
      { headers }
    );

  }

  GetCustomersToActive(fechaIni: Date, fechaFin: Date) {

    const CURRENTUSER = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${CURRENTUSER.access_token}`
    });

    return this.http.get<BusinessPartnersMobileResponse>(`${AppConstants.apiUrl}api/BusinessPartners/GetBPforAppCreate?fechaIni=${fechaIni}&fechaFin=${fechaFin}&userMappId=${CURRENTUSER.userMappId}`,
      { headers }
    );

  }

  // metodo para obtener los canales
  getChannels() {

    let currentUser = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${currentUser.access_token}`
    });

    return this.http.get<CardGroupsResponse>(`${AppConstants.apiUrl}api/BusinessPartners/GetGroupCode?userMappId=${currentUser.userMappId}`,
      { headers }
    );

  }

  // metodo para obtener los sub tipos (sub canales)
  getSubChannels() {

    let currentUser = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${currentUser.access_token}`
    });

    return this.http.get<SubTipoResponse>(`${AppConstants.apiUrl}api/BusinessPartners/GetSubTipo?userMappId=${currentUser.userMappId}`,
      { headers }
    );

  }

  activateCustomer(CardCode: string) {

    const session = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${session.access_token}`
    });

    return this.http.put<BaseResponse>(`${AppConstants.apiUrl}api/BusinessPartners/ActivateCustomer?CardCode=${CardCode}&userMappId=${session.userMappId}`,
      {}, { headers }
    );

  }

  updateCoordsSAP(location: Location, cardCode: string) {

    const CURRENTUSER = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${CURRENTUSER.access_token}`
    });

    return this.http.post<BaseResponse>(`${AppConstants.apiUrl}api/BusinessPartners/UpdateCoordsSAP?cardCode=${cardCode}&userMapId=${CURRENTUSER.userMappId}`,
      location,
      { headers }
    );

  }

  getCustomerLocations(CardCode: string) {

    const CURRENTUSER = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${CURRENTUSER.access_token}`
    });

    return this.http.get(`${AppConstants.apiUrl}api/BusinessPartners/GetLocations?CardCode=${CardCode}&userMappId=${CURRENTUSER.userMappId}&lang=es`, { headers });
  }
}
