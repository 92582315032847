import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

// MODELOS
import { AppConstants, UsersResponse, BaseResponse, FrequencyListResponse, FrequencyModel } from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class FrequencyService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  GetFrequencyList() {
    let urlApi = `${AppConstants.apiUrl}api/Route/GetFrequencyList`;
    const TOKEN = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    return this.http.get<FrequencyListResponse>(urlApi, { headers });
  }

  sendFreqInfo(grupo: FormGroup, Id: number, days: string) {
    let date = new Date();
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    let Frequency: FrequencyModel = {
      'Id': Id,
      'Description': grupo.value.Description,
      'Mix': grupo.value.comb,
      'Days': days,
      'Active': grupo.value.Active !== '' ? grupo.value.Active : false,
      'UserId': TOKEN.userId,
      'LastTransact': date
    };
    let urlApi = '';
    if (Id === 0) {
      urlApi = 'api/Route/CreateFrequencyRoute';
    } else {
      urlApi = 'api/Route/UpdateFrequencyRoute';
    }
    const url = `${AppConstants.apiUrl}` + urlApi;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    return this.http.post<BaseResponse>(url, Frequency, { headers });
  }
}


