import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from './../services/authentication.service';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardRouteService implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private globals: Globals
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;

    let havePerm = this.globals.viewParamListSubMenu.filter((x) => {
      return x.Name === 'S_VerRuta';
    });
    let expires = new Date(currentUser.ExpireTime);
    let today = new Date();

    if (currentUser && (today <= expires)) { // posee token y el mismo aun no expira
      if (havePerm.length > 0) { // tiene permiso para ingresar a la pagina de ruta
        return true;
      } else { // no tiene permiso para ingresar a la pagina de ruta
        this.router.navigate(['/home']);
        return false;
      }
    }

    this.authenticationService.logout();
    return false;
  }
}
