import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { ApiResponse, AppConstants, IPagedStashedDocuments, IStashedDocument } from '../models';
import { IGlobalSearch } from '../models/i-global-search';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(
    private http: HttpClient,
    private storage: StorageService) { 

  }

  GetBackedupDocuments(globalSearch: IGlobalSearch) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    
    return this.http.post<ApiResponse<IStashedDocument[]>>(
      `${AppConstants.apiUrl}api/Documents/GetBackedupDocuments`, 
      globalSearch, 
      { headers });
  }

  GetPagedBackedupDocuments(globalSearch: IGlobalSearch, offset: number, rows: number) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    
    return this.http.post<ApiResponse<IPagedStashedDocuments>>(
      `${AppConstants.apiUrl}api/Documents/GetPagedBackedupDocuments?offset=${offset}&rows=${rows}`, 
      globalSearch, 
      { headers });
  }
}
