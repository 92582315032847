import { moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { NgbModal, NgbTabset } from "@ng-bootstrap/ng-bootstrap";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { first } from "rxjs/operators";
import { PermissionType, REPORT_PARAMETER } from "src/app/enums/enums";
import {
  AppConstants,
  ParameterOption,
  PermissionsModel,
  Report,
  Report2,
  ReportParameter2,
  ReportUser,
} from "src/app/models";
import { Menu } from "src/app/models/constantes";
import {
  AlertService,
  CommonService,
  CompanyService,
  PermissionsService,
  RptManagerService,
} from "src/app/services";

@Component({
  selector: "app-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.css"],
})
export class ReportsComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild("tabset") nav: NgbTabset;

  reportForm: FormGroup;
  parameterForm: FormGroup;
  parameterValueForm: FormGroup;

  reportUsers: ReportUser[];
  parameters: ReportParameter2[];
  reports: Report[];
  report: File;
  parameterOptions: ParameterOption[];
  parameterOptionsModal: ParameterOption[];

  parameterSubgroup1: ReportParameter2[];
  parameterSubgroup2: ReportParameter2[];
  parameterSubgroup3: ReportParameter2[];
  parameterSubgroup4: ReportParameter2[];
  paramaterListGroup: ReportParameter2[][];

  currentSection = 1;
  layoutConfigForm: FormGroup;
  perms: any[];
  menuOptions: any[];

  availableDevices = [
    { Id: 1, Name: "Navegador", Menu: [] },
    {
      Id: 2,
      Name: "Aplicación móvil",
      Menu: [
        { Name: "Inicio", Id: 1 },
        { Name: "Mis rutas", Id: 2 },
        { Name: "Documentos", Id: 3 },
        { Name: "Datos maestros", Id: 10 },
        { Name: "Bancos", Id: 12 },
        { Name: "Reportes", Id: 46, Default: true },
      ],
    },
    { Id: 3, Name: "Todos", Menu: [] },
  ];

  permissionForm: FormGroup;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private alertService: AlertService,
    private commonService: CommonService,
    private rptManagerService: RptManagerService,
    private companyService: CompanyService,
    private permissionService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.reportForm = this.formBuilder.group({
      Id: 0,
      Name: ["", Validators.required],
      DisplayName: ["", Validators.required],
      ReportUserId: [0, Validators.required],
    });

    this.parameterForm = this.formBuilder.group({
      Id: [0],
      ReportId: [0],
      Name: ["", Validators.required],
      DisplayName: ["", Validators.required],
      Type: [1, Validators.required],
      Required: ["S", Validators.required],
    });

    this.parameterValueForm = this.formBuilder.group({
      Key: ["", Validators.required],
      Value: ["", Validators.required],
    });

    this.layoutConfigForm = this.formBuilder.group({
      Device: [1, Validators.required],
      Perm: [""],
      Node: [-1, Validators.required],
    });

    this.permissionForm = this.formBuilder.group({
      Name: ["", Validators.required],
      Description: ["", Validators.required],
      Type: [PermissionType.ReportUse],
    });

    this.availableDevices[0].Menu = Menu.map((x, index) => {
      return { Name: x.Name, Default: x.Name === "Reportes", Id: index };
    }).filter((x) => x.Name !== "Salir");

    this.initializeApp();

    if (!AppConstants.KeyReportManager) {
      this.companyService
        .GetKeyReportManager()
        .pipe(first())
        .subscribe((response) => {
          if (response.result) {
            AppConstants.KeyReportManager = response.Data;
            this.getReportUsers();
            this.getReports();
          } else {
            this.alertService.errorAlert(
              "Ocurrió un error obteniendo la configuración de reportes de la compañía"
            );
          }
        });
    } 
    else
    {
      this.getReportUsers();
      this.getReports();
    }
  }

  initializeApp() {
    this.perms = [];
    this.parameterSubgroup1 = [];
    this.parameterSubgroup2 = [];
    this.parameterSubgroup3 = [];
    this.parameterSubgroup4 = [];
    this.paramaterListGroup = [];
    this.parameters = [];
    this.paramaterListGroup.push(
      this.parameterSubgroup1,
      this.parameterSubgroup2,
      this.parameterSubgroup3,
      this.parameterSubgroup4
    );
    this.parameterOptions = [];
    this.parameterOptionsModal = [];
    this.report = null;
    this.resetParameterForm();
    this.resetReportForm();
    this.resetLayoutConfigForm();
    this.resetParameterValueForm();
    this.getPermissions();
  }

  getReportUsers() {
    this.blockUI.start();
    this.reportUsers = [];
    this.rptManagerService
      .GetReportUsers()
      .pipe(first())
      .subscribe(
        (response) => {
          this.blockUI.stop();
          if (response.Result) this.reportUsers = response.ReportUsers;
          else this.alertService.errorAlert(response.ErrorInfo.Message);
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.errorAlert(err);
        }
      );
  }

  getReports() {
    this.blockUI.start();
    this.reports = [];
    this.rptManagerService
      .GetReports()
      .pipe(first())
      .subscribe(
        (response) => {
          this.blockUI.stop();
          if (response.Result) {
            this.reports = response.Reports;
          } else {
            this.alertService.errorAlert(response.ErrorInfo.Message);
          }
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.errorAlert(err);
        }
      );
  }

  getPermissions() {
    this.blockUI.start("Procesando...");

    this.permissionService
      .getPerms()
      .pipe(first())
      .subscribe(
        (response) => {
          this.blockUI.stop();
          if (response.result) {
            this.perms = response.PermissionsList.filter(
              (x) => x.Type === PermissionType.ReportUse
            );
          } else {
            this.alertService.errorAlert(response.errorInfo.Message);
          }
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.errorAlert(err);
        }
      );
  }

  async HandlePostOrPutReport(report: Report2) {
    this.blockUI.start("Procesando...");
    const applicationId = await this.rptManagerService.GetApplicationId(
      AppConstants.KeyReportManager
    );
    report.ApplicationId = applicationId;

    this.rptManagerService
      .HandlePostOrPutReport(report)
      .pipe(first())
      .subscribe(
        (response: any) => {
          this.blockUI.stop();

          if (response.Result) {
            this.alertService.successInfoAlert(
              "Proceso finalizado exitosamente"
            );

            this.initializeApp();
            this.getReports();
          } else {
            this.alertService.errorAlert(response.ErrorInfo.Message);
          }
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.errorAlert(err);
        }
      );
  }

  createPermission() {
    this.blockUI.start("Procesando...");
    let permission: PermissionsModel = {
      Active: true,
      Description: this.permissionForm.get("Description").value,
      Id: 0,
      Name: this.permissionForm.get("Name").value,
      Type: this.permissionForm.get("Type").value,
    };

    this.permissionService
      .PostPermission(permission)
      .pipe(first())
      .subscribe(
        (response) => {
          this.blockUI.stop();

          if (response.result) {
            this.dismissModal(true);
            this.alertService.successInfoAlert(
              "Proceso finalizado exitosamente"
            );
            this.getPermissions();
          } else {
            this.alertService.errorAlert(response.errorInfo.Message);
          }
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.errorAlert(err);
        }
      );
  }

  onClickPrintReport(reportId: number) {
    this.router.navigateByUrl(`print-report/${reportId}`);
  }

  onClickDownloadFile() {
    if (!this.reportForm.get("Id").value) return;
    this.blockUI.start();
    this.rptManagerService
      .DownloadFile(Number(this.reportForm.get("Id").value))
      .pipe(first())
      .subscribe(
        (response) => {
          this.blockUI.stop();

          if (response.Result) {
            this.commonService.downloadFile(
              response.Print,
              this.reportForm.get("DisplayName").value,
              "application/octet-stream",
              "rpt"
            );
          } else {
            this.alertService.errorAlert(response.ErrorInfo.Message);
          }
        },
        (err) => {
          this.blockUI.stop();
        }
      );
  }

  onClickEditReport(report: Report) {
    this.nav.select("tabCrud");

    this.reportForm.reset({
      Id: report.Id,
      Name: report.Name,
      DisplayName: report.DisplayName,
      ReportUserId: report.ReportUserId,
    });

    if (report.LayoutConfig) {
      const layoutConfig = JSON.parse(report.LayoutConfig);

      this.layoutConfigForm.reset({
        Perm: layoutConfig.Perm,
        Device: layoutConfig.Device,
        Node: layoutConfig.Position ? layoutConfig.Position.Id : -1,
      });
    }

    this.rptManagerService
      .GetParameters(report.Id)
      .pipe(first())
      .subscribe(
        (response) => {
          if (response.Result) {
            if (response.Parameters && response.Parameters.length > 0) {
              response.Parameters.forEach((x) => {
                switch (x.GridCol) {
                  case 0:
                    this.parameterSubgroup1.push(x);
                    break;
                  case 1:
                    this.parameterSubgroup2.push(x);
                    break;
                  case 2:
                    this.parameterSubgroup3.push(x);
                    break;
                  case 3:
                    this.parameterSubgroup4.push(x);
                    break;
                }
              });
            }
          } else {
            this.alertService.errorAlert(response.ErrorInfo.Message);
          }
        },
        (err) => {
          console.log(err);
          this.alertService.errorAlert(err);
        }
      );
  }

  async onClickSaveChanges() {
    if (this.reportForm.invalid || this.layoutConfigForm.invalid) return;

    const report = this.getReportModel();

    if (!report.Parameters || report.Parameters.length === 0) {
      const confirmResult = await this.alertService.confirmationAlert(
        "Confirmación",
        "No has agregado parámetros al reporte. ¿Desea continuar?",
        "Continuar"
      );

      if (!confirmResult) return;
    }

    if (this.report !== null) {
      this.blockUI.start();

      this.rptManagerService
        .SaveReportFile(this.report)
        .pipe(first())
        .subscribe(
          (response: any) => {
            this.blockUI.stop();

            if (response.Result) {
              this.HandlePostOrPutReport(report);
            } else {
              this.blockUI.stop();
              this.alertService.errorAlert(response.ErrorInfo.Message);
            }
          },
          (err) => {
            this.blockUI.stop();
            this.alertService.errorAlert(err);
          }
        );
    } else {
      this.HandlePostOrPutReport(report);
    }
  }

  async onClickDeleteParam(paramList: ReportParameter2[], index: number) {
    let confirmResult = await this.alertService.confirmationAlert(
      "Confirmación",
      "¿Desea eliminar el parámetro?",
      "Eliminar"
    );
    if (confirmResult) paramList.splice(index, 1);
  }

  onClickAddParameter() {
    let parameter = this.getParameterFromForm();
    this.parameterSubgroup1.push(parameter);
    this.resetParameterForm();
    this.resetParameterValueForm();
    this.parameterOptions = null;
  }

  onClickCreatePermission(modalPermission: any) {
    let config: any = { size: "md", backdrop: true };
    let modal = this.modalService.open(modalPermission, config);

    modal.result.then(
      () => {},
      () => {
        this.permissionForm.reset({ Type: PermissionType.ReportUse });
      }
    );
  }

  async onClickDeleteReport(report: Report2) {
    let confirmResult = await this.alertService.confirmationAlert(
      "Confirmación",
      "¿Desea eliminar el reporte?",
      "Eliminar"
    );

    if (!confirmResult) return;

    this.blockUI.start("Procesando...");

    this.rptManagerService
      .DeleteReport(report.Id)
      .pipe(first())
      .subscribe(
        (response) => {
          this.blockUI.stop();

          if (response.Result) {
            this.alertService.successInfoAlert(
              "Proceso finalizado exitosamente"
            );

            this.getReports();
          } else {
            this.alertService.errorAlert(response.ErrorInfo.Message);
          }
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.errorAlert(err);
        }
      );
  }

  onParameterTypeChange(event: any, modalParameterOptions: any) {
    if (Number(event.target.value) === REPORT_PARAMETER.MultipleOption) {
      if (!this.parameterOptions) this.parameterOptions = [];

      this.showParameterOptionsModal(
        modalParameterOptions,
        this.parameterOptions
      );
    }
  }

  onClickParameterOptions(
    parameter: ReportParameter2,
    modalParameterOptions: any
  ) {
    this.showParameterOptionsModal(modalParameterOptions, parameter.Options);
  }

  onClickAddParameterValidValue() {
    this.parameterOptionsModal.push({
      ...this.parameterValueForm.value,
      Id: 0,
      ParameterId: 0,
    });
    this.resetParameterValueForm();
  }

  onClickDeleteValidValue(index: number) {
    if (this.parameterOptionsModal.length === 1) {
      this.alertService.infoInfoAlert(
        "Debes agregar al menos una opción válida"
      );
      return;
    }

    this.parameterOptionsModal.splice(index, 1);
  }

  get reportFormControls() {
    return this.reportForm.controls;
  }

  get parameterFormControls() {
    return this.parameterForm.controls;
  }

  getReportModel() {
    this.parameters = [];
    this.paramaterListGroup.forEach((list, col) => {
      list.forEach((param, row) => {
        param.GridCol = col;
        param.GridRow = row;
        this.parameters.push(param);
      });
    });

    const LayoutConfig = {
      Perm: this.layoutConfigForm.get("Perm").value,
      Device: +this.layoutConfigForm.get("Device").value,
      Position:
        +this.layoutConfigForm.get("Device").value !== 3
          ? this.getReportPosition()
          : null,
    };

    const report: Report2 = {
      Active: true,
      ApplicationId: 0,
      ReportUserId: Number(this.reportForm.get("ReportUserId").value),
      DisplayName: this.reportForm.get("DisplayName").value,
      Name: this.reportForm.get("Name").value,
      Id: this.reportForm.get("Id").value,
      Parameters: this.parameters,
      LayoutConfig: JSON.stringify(LayoutConfig),
    };

    return report;
  }

  getReportPosition() {
    const device = this.availableDevices.find(
      (x) => x.Id === +this.layoutConfigForm.get("Device").value
    );

    let selectedNode = device.Menu.find(
      (x) => x.Id === +this.layoutConfigForm.get("Node").value
    );

    if (!selectedNode) selectedNode = device.Menu.find((x) => x.Default);

    const position = {
      Id: +selectedNode.Id,
      Name: selectedNode.Name,
      GoesTo: null,
    };

    return position;
  }

  onReportSelected($event) {
    this.report = <File>$event.target.files[0];

    if (!this.report) return;
    if (!this.commonService.isValidFile(this.report, ["rpt"])) {
      this.report = null;
      this.alertService.warningInfoAlert("Archivo no soportado");
      return;
    }

    this.reportForm.get("Name").setValue(this.report.name);
  }

  getParameterFromForm() {
    let parameter: ReportParameter2 = {
      Id: Number(this.parameterForm.get("Id").value),
      Name: this.parameterForm.get("Name").value,
      DisplayName: this.parameterForm.get("DisplayName").value,
      ReportId: Number(this.parameterForm.get("ReportId").value),
      Type: Number(this.parameterForm.get("Type").value),
      GridCol: 0,
      GridRow: 0,
      Options: this.parameterOptions,
      Required: this.parameterForm.get("Required").value === "S",
    };

    return parameter;
  }

  getParameterType(type) {
    switch (type) {
      case REPORT_PARAMETER.Alpha:
        return "Alfanumérico";
      case REPORT_PARAMETER.Boolean:
        return "Check";
      case REPORT_PARAMETER.Date:
        return "Fecha";
      case REPORT_PARAMETER.Numeric:
        return "Numérico";
      case REPORT_PARAMETER.MultipleOption:
        return "Opción múltiple";
      default:
        return "";
    }
  }

  onItemDropped(event) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  dismissModal(result: boolean) {
    this.modalService.dismissAll(result);
  }

  onTabChange($event: any) {
    if ($event.nextId === "tabList") {
      this.initializeApp();
    }
  }

  showParameterOptionsModal(
    modalParameterOptions: any,
    options: ParameterOption[]
  ) {
    this.parameterOptionsModal = options;
    let modal = this.modalService.open(modalParameterOptions, {
      size: "lg",
      backdrop: true,
    });

    modal.result.then(
      () => {},
      () => {
        options = this.parameterOptionsModal;
        this.parameterOptionsModal = null;
      }
    );
  }

  getDeviceMenu() {
    let device = this.availableDevices.find(
      (x) => x.Id === +this.layoutConfigForm.get("Device").value
    );
    return device ? device.Menu : [];
  }

  resetParameterForm() {
    this.parameterForm.reset({
      Id: 0,
      ReportId: 0,
      Name: "",
      Type: 1,
      Required: "S",
    });
  }

  resetReportForm() {
    this.reportForm.reset({
      Id: 0,
      Name: "",
      DisplayName: "",
      ConnectionType: 1,
    });
  }

  resetParameterValueForm() {
    this.parameterValueForm.reset({
      Key: "",
      Value: "",
    });
  }

  resetLayoutConfigForm() {
    this.layoutConfigForm.reset({
      Device: 1,
      Perm: "",
      Node: -1,
    });
  }
}
