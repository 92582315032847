import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Location } from '@angular/common';
// MODELOS
import { Globals } from './../../globals';
import { PermissionsSelectedResponse, LogoCompanyResponse } from './../../models/index';
import Chart,{ChartConfiguration} from '../../../assets/scripts/chart.js';
// RUTAS

// COMPONENTES

// SERVICIOS
import { AlertService, CompanyService, StorageService, PermissionsService, AuthenticationService } from '../../services/index';
import { ChartService } from 'src/app/services/chart.service';
import { CHART_COLORS, transparentize } from 'src/assets/js/utils.chart.js';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import {  FormBuilder, FormGroup, NgbModal, Validators } from 'src/app/common/angular.index';
import { Menu } from 'src/app/models/constantes';
import { QuickAccessRoute } from 'src/app/models/quick-access-route';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit,AfterViewInit,OnDestroy {
  // VARBOX
  isRaiseQuickAccesModal: boolean;
  UIRoutes: string[];
  accessList: any[];
  quickAccessForm: FormGroup;
  logoPath: string; // path del logo
  currentUser: any; // usuario actual logueado
  userMappId: number; // id de asignacion del usuario seleccionado par la creacion de la ruta
  currentUserSubscription: Subscription; // suscripcion para obtener el usuario actual
  
  @BlockUI() blockUI: NgBlockUI;
  @ViewChildren('canva') private chartCanvas: QueryList<ElementRef>
  private chartCanvasSubscription: Subscription;
  charts: ChartConfiguration[];
  renderCharts: Chart[]; 
  domainApp: string;
  constructor(private companyService: CompanyService,
    private alertService: AlertService,
    private storage: StorageService,
    private permsService: PermissionsService,
    private globals: Globals,
    private authenticationService: AuthenticationService,
    private chartService: ChartService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private location: Location
  ) {
    this.currentUserSubscription = this.authenticationService.currentUser.subscribe(user => {
      this.currentUser = user;
    });
    
  }

  ngOnInit() {
    this.initializePage();
    this.getQuickAccess();   
    this.GetUIRouteNames();
  }

  initializePage() {
    this.isRaiseQuickAccesModal = false;
    this.UIRoutes = [];
    this.charts = [];
    this.renderCharts = [];
    this.currentUser = JSON.parse(this.storage.getCurrentSession());
    this.userMappId = parseInt(this.currentUser.userMappId);
    this.quickAccessForm = this.formBuilder.group({
      Name: ['', Validators.required],
      RouteName: ['', Validators.required],
    });
    this.getPermsByUser();
  }

  ngAfterViewInit(): void {
    this.GetMapCharts();
  }
 

  GetMapCharts() {
    setTimeout(_=> this.isRaiseQuickAccesModal = true);
    this.blockUI.start();
    this.chartService.GetCharts().subscribe(req =>{
      try{
        if(req.result){
          req.Data.forEach(chart => {
            // Mapeo de colores y opciones
            chart.options = JSON.parse(chart.options);
            chart.data.datasets.forEach(dataset => {
              let color = dataset.color;
              delete dataset.color;
              // Se personaliza la configuracion de color segun corresponda
              switch(chart.type) { 
                case 'pie':
                case 'bar':
                case 'doughnut':
                  let colors: string[] = JSON.parse(color); 
                  dataset["backgroundColor"] = []
                  colors.forEach(c =>  dataset["backgroundColor"].push(CHART_COLORS[c]));
                  break;
                default: 
                  dataset["borderColor"] = CHART_COLORS[color];
                  dataset["backgroundColor"] = transparentize(CHART_COLORS[color], 0.5);
                  break;
             } 
            });
            this.charts.push(chart as ChartConfiguration);
          });
          this.blockUI.stop();
          this.chartCanvasSubscription = this.chartCanvas.changes.subscribe(canva => 
            {
              for(let c = 0; c < canva.length; c++) {
                this.renderCharts.push(new Chart(canva.toArray()[c].nativeElement, this.charts[c]));
              };
            }
          );
        } else {
          this.blockUI.stop();
        }
       setTimeout(_=> this.isRaiseQuickAccesModal = false);
      } catch (ex){
        console.warn(ex);
        setTimeout(_=> this.isRaiseQuickAccesModal = false);
        this.blockUI.stop();
      }
    }, error => {
      setTimeout(_=> this.isRaiseQuickAccesModal = false);
      console.warn(error);
      this.blockUI.stop();
     
    });
  }

  getPermsByUser(): void{
    this.globals.generalWebPermList.length = 0;
    this.globals.viewParamListMenu.length = 0;
    this.globals.viewParamListSubMenu.length = 0;

    this.permsService.getPermsByUser().subscribe((data: PermissionsSelectedResponse) => {
      if (data.result) {
        this.globals.viewParamListMenu = data.PermissionsList.filter(x => x.Type === 2);
        this.globals.viewParamListSubMenu = data.PermissionsList.filter(x => x.Type === 3);
        this.globals.generalWebPermList = data.PermissionsList.filter(x => x.Type === 7);
      } else {
        this.alertService.warningInfoAlert(`${data.errorInfo.Message}`);
      }
    }, error => {
      this.alertService.errorInfoAlert(`${error}`);
    });
  }


  onClickQuickAccessModal(modal: any) {
    this.isRaiseQuickAccesModal = true;
    this.modalService.open(modal, {
      centered: true,
      size: 'lg',
      backdrop: "static",
      keyboard: false
    });
  }

  onClickSaveChanges(): void{
    if (!this.accessList) this.accessList = [];
    
    let quickAccess: QuickAccessRoute = this.GetUIRouteData(this.quickAccessForm.get('RouteName').value);
    
    quickAccess.Name = this.quickAccessForm.get('Name').value

    this.accessList.push(quickAccess);

    this.dismissModal(true);

    this.storage.setQuickAccessList(this.accessList);
  }

  onClickDeleteAccess(index: number) {
    this.isRaiseQuickAccesModal = true;
    this.accessList.splice(index, 1);
    this.storage.setQuickAccessList(this.accessList);
    setTimeout(_=> this.isRaiseQuickAccesModal = false);
  }

  dismissModal(result: boolean): void{
    this.resetFormInitialState();
    this.modalService.dismissAll(result);
    this.isRaiseQuickAccesModal = false;
  }

  resetFormInitialState(): void{
    this.quickAccessForm.reset({
      Name: '',
      URL: '',
    });
  }

  getQuickAccess(): void{
    this.accessList = this.storage.getQuickAccess();
  }
  
  GetUIRouteNames(): void{
    Menu.forEach(r => {
      if(r.Route){
        this.UIRoutes.push(r.Name);
      }
      if(r.Children.length > 0){
        r.Children.forEach(rc => {
          this.UIRoutes.push(rc.Name);
        })
      }
    });
  }

  GetUIRouteData(routeName: string): QuickAccessRoute{
    const quickAccess = {} as QuickAccessRoute;

    Menu.forEach(r => {
      if(r.Name === routeName){
        quickAccess.Icon = r.Icon;
        quickAccess.Url = `/${r.Route}`;
      }else{
        if(r.Children.length > 0){
          r.Children.forEach(rc=>{
            if(rc.Name === routeName){
              quickAccess.Icon = rc.Icon;
              quickAccess.Url = `/${rc.Route}`;
            }
          });
        }
      }
    });

    return quickAccess;
  }
  ngOnDestroy(): void {
    if (this.chartCanvasSubscription) setTimeout(_=> this.chartCanvasSubscription.unsubscribe());
  }

 
}
