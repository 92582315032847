import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { FormGroup } from "@angular/forms";

// MODELOS
import {
  AppConstants,
  UsersResponse,
  UsersAssingsResponse,
  BaseResponse,
  GeoConfigsResponse,
  RouteAdministrators,
  UserAssignsWithNameResopnse,
} from "./../models/index";
import { StorageService } from "./storage.service";
import { RouteTypes, Geoconfigs } from "../enums/enums";

// RUTAS

// COMPONENTES

// SERVICIOS

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private http: HttpClient, private storage: StorageService) {}

  // metodo que obtiene los usuarios de la aplicacion
  getUsers() {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });
    return this.http.get<UsersResponse>(
      `${AppConstants.apiUrl}api/Users/GetUsers`,
      { headers }
    );
  }

  // metodo que obtiene los usuarios de la aplicacion
  getUsersMobile() {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });
    return this.http.get<UsersResponse>(
      `${AppConstants.apiUrl}api/Users/GetUsersMobile?lang=es`,
      { headers }
    );
  }

  // metodo para obtener las asignaciones de usuarios
  getUsersAssings() {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });
    return this.http.get<UsersAssingsResponse>(
      `${AppConstants.apiUrl}api/Users/GetUserAssings`,
      { headers }
    );
  }

  sendUserInfo(grupo: FormGroup, Id: string) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });
    let User = {
      userId: Id,
      UserName: grupo.value.UserName,
      userEmail: grupo.value.userEmail,
      password: grupo.value.userPass,
      Active: grupo.value.Active !== "" ? grupo.value.Active : false,
      Scheduling: grupo.value.Scheduling !== "" ? grupo.value.Scheduling : false,
      PasswordEmail: grupo.value.userPassEmail
    };

    let urlApi = "";
    if (Id === "0") {
      urlApi = "api/Users/CreateUser";
    } else {
      urlApi = "api/Users/UpdateUser";
    }

    const url = `${AppConstants.apiUrl}` + urlApi;

    return this.http.post<BaseResponse>(url, User, { headers });
  }

  sendUserAssingsInfo(grupo: FormGroup, Id: string, SlpName: string) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });
    let User = {
      Active: grupo.value.Active !== "" ? grupo.value.Active : false,
      CenterCost: grupo.value.CenterCost,
      CompanyId: grupo.value.CompanyId,
      Id: Id,
      SAPPass: grupo.value.SAPPass,
      SAPUser: grupo.value.cSAPUser,
      SlpCode: grupo.value.SlpCode,
      SlpName: SlpName,
      WhCode: grupo.value.WhCode,
      SuperUser: grupo.value.SuperUser !== "" ? grupo.value.SuperUser : false,
      UserId: grupo.value.cUserName,
    };

    let urlApi = "";
    if (Id === "0") {
      urlApi = "api/Users/CreateUserAssings";
    } else {
      urlApi = "api/Users/UpdateUserAssings";
    }
    // const token = JSON.parse(this.storage.getCurrentSession());
    const url = `${AppConstants.apiUrl}` + urlApi;

    return this.http.post<BaseResponse>(url, User, { headers });
  }

  getGeoconfigs() {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });
    return this.http.get<GeoConfigsResponse>(
      `${AppConstants.apiUrl}api/Users/GetGeoConfigs?userMappId=${TOKEN.userMappId}&lang=es`,
      { headers }
    );
  }

  getRouteAdministrators() {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });
    return this.http.get<RouteAdministrators>(
      `${AppConstants.apiUrl}api/Users/GetAdministrators`,
      { headers }
    );
  }

  getRouteAdminType() {
    let geoConfigs: any[] = this.storage.get("geoConfigs");
    if (!geoConfigs || geoConfigs.length === 0) return 0;

    let userType = geoConfigs.find((x) => x.Key == Geoconfigs.RouteAdmin);
    
    if (!userType)
      userType = geoConfigs.find(
        (x) => x.Key == RouteTypes.Bill || x.Key == RouteTypes.Ship
      );
    if (!userType) return 0;

    return Number(userType.Key);
  }

  GetUserAssignsWithName() {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });
    return this.http.get<UserAssignsWithNameResopnse>(
      `${AppConstants.apiUrl}api/Users/UserAssignWithName`,
      { headers }
    );
  }

  PutUserGeoRol(userMappId: number, geoRolId: number) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${TOKEN.access_token}`,
    });
    return this.http.put<BaseResponse>(
      `${AppConstants.apiUrl}api/Users/PutUserGeoRol?userMappId=${userMappId}&geoRolId=${geoRolId}`,
      {},
      { headers }
    );
  }
}
