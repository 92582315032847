import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

// MODELOS
import { PromotionsModel, PromotionsResponse, DiscountPromoType, SequencePromoType } from '../../../models/index';
import { Globals } from './../../../globals';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService, PromotionsService } from './../../../services/index';
import { AlertService } from '../../../services/alert.service';
import { BaseResponse } from '../../../models/responses';

@Component({
  selector: 'app-list-promotions',
  templateUrl: './list-promotions.component.html',
  styleUrls: ['./list-promotions.component.css']
})
export class ListPromotionsComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  // lista de las promociones existentes
  promotionsList: PromotionsModel[] = [];
  currentUser: any; // usuario actual logueado
  userMappId: number; // id de asignacion del usuario seleccionado par la creacion de la ruta
  title: string; // titulo de la pagina

  promotionForm: FormGroup; // formulario de promocion
  discountPromoTypeList: any[] = []; // lista con los tipos de descuento de promocion
  sequencePromoTypeList: any[] = []; // lista con los tipos de secuencia de promocion
  promotion: PromotionsModel; // promocion a editar

  // modal de promocion
  closeResult: string;
  modalPromotion: any; // instancia de la modal de promocion

  // Identificador de la promocion
  promotionId: number;
  // tipo de accion 'Crear' o 'Actualizar'
  action: string;

  // PERMISOS
  G_CreatePromo: boolean; // permiso para la creacion de rutas
  G_EditPromo: boolean; // permiso para la edicion de rutas

  constructor(private promotionsService: PromotionsService,
    private storage: StorageService,
    private alertService: AlertService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private globals: Globals,
  ) {
    this.title = 'Promociones';
    this.G_CreatePromo = this.globals.generalWebPermList.some(x => x.Name === 'G_CreatePromo');
    this.G_EditPromo = this.globals.generalWebPermList.some(x => x.Name === 'G_EditPromo');
  }

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.promotionId = 0;
    this.discountPromoTypeList = DiscountPromoType;
    this.sequencePromoTypeList = SequencePromoType;
    this.currentUser = JSON.parse(this.storage.getCurrentSession());
    this.userMappId = parseInt(this.currentUser.userMappId);
    this.GetPromotions();
    this.promotionForm = this.setPromotionForm();
  }

  // convenience getter for easy access to form fields
  get fPromotion() { return this.promotionForm.controls; }

  // funcion para setear el formulario de promocion
  setPromotionForm() {
    return this.fb.group({
      Rule: ['', [Validators.required]],
      Quantity: ['', [Validators.required, Validators.min(1)]],
      UnitMeasurement: ['', [Validators.required]],
      ItemCode: ['', [Validators.required]],
      Discount: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
      DiscountType: ['', [Validators.required]],
      Sequence: ['', [Validators.required]],
      UseDescripPromo: [false],
      DescripPromo: [''],
    });
  }

  // funcion para obtener las promociones
  GetPromotions() {
    this.blockUI.start(); // Start blocking
    this.promotionsService.GetPromotions(this.userMappId)
      .subscribe((data: PromotionsResponse) => {
        this.blockUI.stop(); // Stop blocking
        this.promotionsList.length = 0;
        if (data.result) {
          this.promotionsList = data.promotionsList;
        } else {
          this.alertService.warningAlert(`${data.errorInfo.Message}`);
        }
      }, (error: any) => {
        this.blockUI.stop(); // Stop blocking
        this.alertService.errorAlert(`${error}`);
        console.error(error);
      });
  }

  // funcion para colocarl la info de la promocion al editarla
  updatePromotionForm(promotion: PromotionsModel) {
    this.promotionForm.patchValue({
      Rule: promotion.Rule,
      Quantity: promotion.Quantity,
      UnitMeasurement: promotion.UnitMeasurement,
      ItemCode: promotion.ItemCode,
      Discount: promotion.Discount,
      DiscountType: promotion.DiscountType,
      Sequence: promotion.Sequence,
      UseDescripPromo: promotion.UseDescripPromo,
      DescripPromo: promotion.DescripPromo,
    });
  }


  // funcion para abrir la modal de creacion/edicion de promociones
  openPromotionModal(content, promotion: PromotionsModel) {
    this.promotion = promotion;
    this.promotionForm.reset();
    this.action = 'Crear';
    if (promotion !== null) {
      this.action = 'Actualizar';
      this.updatePromotionForm(promotion);
    }
    this.modalPromotion = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' });
    this.modalPromotion.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // funcion para cerrar la modal de promocion
  close() {
    this.modalPromotion.close({});
  }

  // funcion para crear o modificar una promocion
  CreateUpdatePromotions() {
    if (this.promotionForm.invalid) {
      this.alertService.errorInfoAlert('Formulario invalido');
      return;
    }
    this.blockUI.start();

    if (this.promotion !== null) {
      this.promotionId = this.promotion.Id;
    } else {
      this.promotionId = 0;
    }
    this.promotionsService.CreateUpdatePromotions(this.promotionForm, this.userMappId, this.promotionId).subscribe((data: BaseResponse) => {
      this.blockUI.stop(); // Stop blocking
      if (data.result) {
        this.close();
        this.alertService.successInfoAlert('Proceso finalizado exitosamente');
        this.promotionForm.reset({
          UseDescripPromo: false
        });
        this.GetPromotions();
      } else {
        this.alertService.warningAlert(`${data.errorInfo.Message}`);
      }
    }, (error: any) => {
      this.blockUI.stop(); // Stop blocking
      this.alertService.errorAlert(`${error}`);
      console.error(error);
    });
  }

}
