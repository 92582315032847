import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormControl } from '@angular/forms';

// MODELOS

import { BusinessPartnersMobileResponse, BusinessPartnersMobileModel } from './../../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS

import { BussinesPartnersService, AlertService } from './../../services/index';
import { BaseResponse } from '../../models/responses';

@Component({
  selector: 'app-bpview',
  templateUrl: './bpview.component.html',
  styleUrls: ['./bpview.component.css']
})
export class BPViewComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;


  BPList: BusinessPartnersMobileModel[] = [];

  FechaIni: FormControl;
  FechaFin: FormControl;
  date: Date; // fecha actual
  nowDate: any;
  title: string;

  constructor(private bpService: BussinesPartnersService, private alertService: AlertService) {
  }

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.date = new Date();
    this.nowDate = `${this.date.getFullYear()}-${('0' + (this.date.getMonth() + 1)).slice(-2)}-${('0' + this.date.getDate()).slice(-2)}`;
    this.FechaIni = new FormControl(this.nowDate);
    this.FechaFin = new FormControl(this.nowDate);
    this.title = 'Activación de clientes';
    this.getAllBPToActive();
  }

  getAllBPToActive() {
    let fechaIni = this.FechaIni.value;
    let fechaFin = this.FechaFin.value;
    if (fechaIni <= fechaFin) {
      this.blockUI.start();
      this.bpService.GetCustomersToActive(fechaIni, fechaFin)
        .subscribe((data: BusinessPartnersMobileResponse) => {
          this.blockUI.stop();
          if (data.result) {
            this.BPList = data.customersList;
          } else {
            this.BPList = [];
            this.alertService.warningAlert(`${data.errorInfo.Message}`);
          }
        }, (error) => {
          this.blockUI.stop();
          this.alertService.errorAlert(error);
        });
    } else {
      this.alertService.warningAlert('La fecha de inicio debe ser menor a la fecha final');
    }
  }

  activateCustomer(CardCode: string) {
    this.blockUI.start();
    this.bpService.activateCustomer(CardCode)
      .subscribe((data: BaseResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.alertService.successInfoAlert('Proceso finalizado exitosamente');
          this.removeActivatedCustomer(CardCode);
        } else {
          this.alertService.warningAlert(`${data.errorInfo.Message}`);
        }
      }, (error) => {
        this.blockUI.stop();
        this.alertService.errorAlert(error);
      });
  }

  removeActivatedCustomer(cardCode: string) {
    let index = this.BPList.findIndex(x => x.CardCode == cardCode);
    this.BPList.splice(index, 1);
  }
}
