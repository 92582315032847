import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormControlName, FormGroup, Validators } from "@angular/forms";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { Observable, Subject, merge } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { ActivatedRoute, Router } from "@angular/router";

// MODELOS
import {
  BusinessPartnersModel,
  GetBPResponse,
  CreateRouteMobileResponse,
  Location,
  FrequencyModel,
  FrequencyListResponse,
  RoutesResponse,
  RouteLinesResponse,
  CustomerLocations,
  RouteLinesMobileModel,
  LocationForRoute,
  RoutesMobileModel,
} from "../../../models/index";

// RUTAS

// COMPONENTES

// SERVICIOS
import {
  BussinesPartnersService,
  RouteService,
  AlertService,
  FrequencyService,
  StorageService,
  UserService,
} from "../../../services/index";
import { LocationsResponse, BaseResponse } from "../../../models/responses";
import { CheckType } from "src/app/enums";
import { RouteStatus, Geoconfigs, RouteTypes, CalculationType } from "src/app/enums/enums";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { RouteCalculationService } from '../../../services/route-calculation.service';
import { DecimalPipe, formatDate } from "@angular/common";
import { GoogleLoadScriptService } from '../../../services/google-load-script.service';


@Component({
  selector: "app-create-route",
  templateUrl: "./create-route.component.html",
  styleUrls: ["./create-route.component.css"],
})
export class CreateRouteComponent implements OnInit {
  // VARBOX
  jsonCalculations: string;
  estimatedDistance: number;
  estimatedDuration: number;
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild("instanceBP") instanceBP: NgbTypeahead;
  focusBP$ = new Subject<string>();
  clickBP$ = new Subject<string>();
  @ViewChild("instanceUser") instanceUser: NgbTypeahead;
  focusUser$ = new Subject<string>();
  clickUser$ = new Subject<string>();
  routeId: number;
  title: string; // titulo de la vista
  lat: number;
  lng: number;
  mapZoom: number;
  bpList: BusinessPartnersModel[] = []; // lista de clientes
  cardNamesList: string[] = []; // lista con los nombres de clientes
  cardCodesList: string[] = []; // lista con los codigos de clientes
  bpTypeaheadList: string[] = []; // lista para el typeahead
  userMappId: number; // id de asignacion del usuario seleccionado par la creacion de la ruta
  public routeForm: FormGroup; // variable del formulario para la creacion de la ruta
  actualDate: string; // fecha actual del sistema
  date: Date = new Date();
  frequencyList: FrequencyModel[] = []; // lista de las frecuencias
  currentUser: any; // usuario actual logueado
  isUpdate = false; // variable para reconocer si es un create o update de ruta
  routeCanModify: boolean = true;

  customerAddRoute: CustomerLocations;
  customersInRoute: CustomerLocations[] = [];
  routeLines: RouteLinesMobileModel[] = [];
  locationInEdition: LocationForRoute;
  customerInEdition: string;
  routeInEdition: RoutesMobileModel;
  userType: number;
  map: any;
  customerNameTyped: string = "";
  visitHourForm: FormGroup;
  visitingTimeIsWrong: boolean;

  constructor(
    private bpService: BussinesPartnersService,
    private activatedRoute: ActivatedRoute,
    private routeService: RouteService,
    private fb: FormBuilder,
    private alertService: AlertService,
    private freqService: FrequencyService,
    private router: Router,
    private storage: StorageService,
    private userService: UserService,
    private routeCalculationService: RouteCalculationService,
    private decimalPipe: DecimalPipe,
    private loadScriptService: GoogleLoadScriptService
  ) {
    
  }

  ngOnInit() {
    this.loadScriptService.LoadScript();
    this.initializePage();
  }

  initializePage() {
    this.jsonCalculations = "[]";
    this.estimatedDistance = 0;
    this.estimatedDuration = 0;
    this.currentUser = JSON.parse(this.storage.getCurrentSession());
    this.routeId = parseInt(this.activatedRoute.snapshot.paramMap.get("Id"));
    this.actualDate = `${this.date.getFullYear()}-${(
      "0" +
      (this.date.getMonth() + 1)
    ).slice(-2)}-${("0" + this.date.getDate()).slice(-2)}`;
    this.routeForm = this.setRouteForm();
    this.validateRouteTypeByUserPerm();
    this.customerAddRoute = undefined;
    this.customersInRoute = [];
    this.routeLines = [];
    this.locationInEdition = undefined;
    this.customerInEdition = "";
    this.getAllCustomers();
    this.chargeFreqList();

    if (this.routeId > 0) {
      this.isUpdate = true;
      this.title = "Edición de rutas";
    } else {
      this.isUpdate = false;
      this.title = "Creación  de rutas";
    }
    this.lat = 9.377837;
    this.lng = -83.707081;
    this.mapZoom = 8;
    this.visitHourForm = new FormGroup({});
    this.visitingTimeIsWrong = false;
  }

  // funcion para obtener una lista de clientes segun la compañía seleccionada
  getAllCustomers() {
    this.blockUI.start("Cargando clientes...");

    this.bpList.length = 0;
    this.cardNamesList.length = 0;
    this.cardCodesList.length = 0;
    this.bpTypeaheadList.length = 0;
    this.bpService.GetAllCustomers(false).subscribe(
      (data: GetBPResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.bpList = data.customersList;
          this.cardNamesList = data.CardNames;
          this.cardCodesList = data.CardCodes;
          this.bpList.forEach((element) => {
            this.bpTypeaheadList.push(
              `${element.CardCode} - ${element.CardName}`
            );
          });
          if (this.routeId > 0) {
            this.getRoute();
          }
        } else {
          this.alertService.warningAlert(`${data.errorInfo.Message}`);
        }
      },
      (error) => {
        this.blockUI.stop();
        this.alertService.errorAlert(`${error}`);
      }
    );
  }

  // convenience getter for easy access to form fields
  get fRoute() {
    return this.routeForm.controls;
  }

  // funcion que crea el form de ruta
  setRouteForm() {
    return this.fb.group({
      bussinesPartner: [""],
      routeName: ["", Validators.required],
      frequency: ["", Validators.required],
      type: [{ value: null, disabled: true }, Validators.required],
      ExpirationDate: [formatDate(new Date(), 'yyyy-MM-dd', 'en'), Validators.required]
    });
  }

  // funcion para el typeahead de BP
  searchBP = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.clickBP$.pipe(
      filter(() => !this.instanceBP.isPopupOpen())
    );
    const inputFocus$ = this.focusBP$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? this.bpTypeaheadList
          : this.bpTypeaheadList.filter(
              (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 10)
      )
    );
  };

  onSubmitCreateRoute() {
    if (this.routeForm.invalid || !this.routeForm.get("type").value) {
      this.alertService.warningAlert("Existen campos requeridos inválidos");
      return;
    }

    if (this.routeLines.length === 0) {
      this.alertService.warningAlert(
        "Debe agregar almenos un cliente para crear la ruta"
      );
      return;
    }

    if (
      this.customersInRoute.some(
        (x) => !x.Locations || !x.Locations.some((x) => x.added)
      )
    ) {
      this.alertService.warningAlert(
        "Existen clientes en la ruta sin información de ubicaciones"
      );
      return;
    }
    let currentDate: Date = new Date();

    if(this.routeForm.get("ExpirationDate").value <= formatDate(currentDate, 'yyyy-MM-dd HH:mm:ss', 'en'))
    {
      this.alertService.errorAlert("La fecha de expiración de la ruta debe ser mayor a la fecha actual");
      return;
    }

    if(this.routeId > 0){
      this.blockUI.start("Este proceso puede tardar, espere por favor...");
    }
    else{
      this.blockUI.start("Procesando...");
    }
    this.userMappId = parseInt(this.currentUser.userMappId);
    this.routeService.CreateRouteMobile(
      this.routeForm, 
      this.estimatedDistance, 
      this.estimatedDuration, 
      this.userMappId, 
      this.routeLines, 
      this.routeId).subscribe(
      (data: CreateRouteMobileResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.routeService.PutRouteCalculationDetails(data.routeId, CalculationType.ESTIMATED, this.estimatedDistance, this.estimatedDuration, this.jsonCalculations).subscribe(req => {
            if(req.result){
              this.alertService.successInfoAlert(
                "Proceso finalizado exitosamente"
              );
              if (this.routeId > 0) {
                this.router.navigate(['listRoute']);
              } else {
                this.initializePage();
              }
            } else {
              this.alertService.errorAlert(req.errorInfo.Message);
            }
          }, error => {
            this.alertService.errorAlert(error);
          });
        } else {
          this.alertService.warningAlert(`${data.errorInfo.Message}`);
          console.log(data);
        }
      },
      (error) => {
        this.blockUI.stop();
        this.alertService.errorAlert(`${error}`);
        console.log(error);
      }
    );
  }
 
  selectedBPItem(item) {
    item.preventDefault();

    let code = item.item.split(" - ")[0];

    this.customerNameTyped = "";

    if (!this.customersInRoute.some((x) => x.CardCode.toUpperCase() === code.toUpperCase())) 
    {
      let bpSelected: any = this.bpList.find((bp) => bp.CardCode.toUpperCase() === code.toUpperCase());

      bpSelected.isSelected = false;

      this.customerAddRoute = { ...bpSelected };

      this.getCustomerLocations(this.customerAddRoute);
    } 
    else 
    {
      this.alertService.infoAlert(
        `El cliente ${code} se encuentra incluido en la ruta`
      );
    }
  }

  // funcion para eliminar un cliente del mapa
  async customerDeletionConfirmation(bp: BusinessPartnersModel, index: number) {
    let result = await this.alertService.confirmationAlert(
      `¿Eliminar el cliente ${bp.CardName} de la ruta?`,
      "Todas sus ubicaciones se eliminarán de la ruta",
      "Eliminar"
    );
    if (result) {
      this.deleteAllCustomerLocations(bp.CardCode);
      this.deleteCustomerFromRoute(index);
    }
  }

  // carga la lista de frecuencias que estan registradas en BD
  chargeFreqList() {
    this.blockUI.start();
    this.freqService.GetFrequencyList().subscribe(
      (data: FrequencyListResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.frequencyList = data.frequencyList.filter(f => f.Active);
          this.routeForm.patchValue({ frequency: this.frequencyList[0].Id });
        } else {
          this.alertService.warningAlert(`${data.errorInfo.Message}`);
        }
      },
      (error: any) => {
        this.blockUI.stop();
        this.alertService.errorAlert(`${error}`);
      }
    );
  }

  getRoute() {
    this.blockUI.start();
    this.routeService.GetRoutes().subscribe(
      (data: RoutesResponse) => {
        this.blockUI.stop();
        if (data.result) {
          for (let i = 0; i < data.routeList.length; i++) {
            if (this.routeId === data.routeList[i].Id) {
              if(data.routeList[i].Status !== 1){
                this.routeCanModify = false;
              }
              this.routeInEdition = data.routeList[i];
              this.routeForm.patchValue({ routeName: data.routeList[i].Name });
              this.routeForm.patchValue({ frequency: data.routeList[i].FrequencyId });
              this.routeForm.patchValue({type: data.routeList[i].Type});
              this.routeForm.patchValue({ ExpirationDate: formatDate(data.routeList[i].ExpirationDate, 'yyyy-MM-dd', 'en')});
              this.getRouteLines(this.routeId);
              break;
            }
          }
        } else {
          this.alertService.warningAlert(`${data.errorInfo.Message}`);
        }
      },
      (error: any) => {
        this.blockUI.stop();
        this.alertService.errorAlert(`${error}`);
      }
    );
  }

  getRouteLines(Id: number) {
    this.blockUI.start();
    this.routeService.getRouteLines(Id).subscribe(
      (data: RouteLinesResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.routeLines = data.RouteLines.filter(
            (x) => x.AddressLineId && x.AddressLineId > 0
          );
          this.getCustomersInRoute(this.routeLines);
        } else {
          this.alertService.warningAlert(`${data.errorInfo.Message}`);
        }
      },
      (error: any) => {
        this.blockUI.stop();
        this.alertService.errorAlert(`${error}`);
      }
    );
  }

  markerClicked(infoWindow, gm) {
    if (gm.lastOpen) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
  }

  // Funcion para actualzar las coordenadas de un BP
  updateCoords($event) {
    if (this.locationInEdition) {
      let routeLineToEdit = this.routeLines.find(
        (x) => x.Latitude == Number(this.locationInEdition.Lat)
      );

      routeLineToEdit.Latitude = $event.coords.lat;
      routeLineToEdit.Longitude = $event.coords.lng;

      this.locationInEdition.Lat = $event.coords.lat;
      this.locationInEdition.Long = $event.coords.lng;
    }
  }

  updateCoordsSAP() {
    if (this.locationInEdition) {
      this.blockUI.start();
      this.bpService
        .updateCoordsSAP(
          <Location>this.locationInEdition,
          this.customerInEdition
        )
        .subscribe(
          (data: BaseResponse) => {
            this.blockUI.stop();
            if (data.result) {
              this.alertService.successInfoAlert(
                "Proceso finalizado exitosamente"
              );
            } else {
              this.alertService.warningAlert(`${data.errorInfo.Message}`);
            }
            this.locationInEdition = undefined;
            this.customerInEdition = "";
          },
          (error) => {
            this.blockUI.stop();
            this.alertService.errorAlert(`${error}`);
            console.log(error);
            this.locationInEdition = undefined;
            this.customerInEdition = "";
          }
        );
    }
  }

  getLocationsAddedByCustomer(customer: CustomerLocations) {
    if (customer.Locations) {
      return customer.Locations.filter((x) => x.added);
    }
  }

  getCustomerLocations(customer: CustomerLocations) {
    this.blockUI.start();

    this.bpService.getCustomerLocations(customer.CardCode).subscribe(
      (locResponse: LocationsResponse) => {

        this.blockUI.stop();

        if (locResponse.result) 
        {
          const locations = locResponse.Locations;

          const routeDestinationType = this.fRoute.type.value == "5" ? 2 : 1;

          if (!locations.some((x) => x.Type === routeDestinationType)) 
          {
            this.alertService.warningAlert(`El cliente ${customer.CardCode} no posee ubicaciones para este tipo de ruta`);

            this.customerAddRoute = null;
            
            return;
          }

          this.convertLocationsToLocationForRoute(
            locResponse.Locations,
            customer
          );
          
          this.GenerateVisitingTimeControls(customer);

          document.getElementById("open-locations-modal").click();

        } 
        else 
        {
          this.alertService.warningAlert(
            `El cliente ${customer.CardCode} no posee ubicaciones`
          );

          this.customerAddRoute = null;
        }
      },
      (error) => {
        this.alertService.errorAlert(error);
        this.blockUI.stop();
      }
    );
  }

  convertLocationsToLocationForRoute(locations: Location[], customerForRoute: CustomerLocations) {
    customerForRoute.Locations = [];
    // Agregamos las ubicaciones segun el orden de las lineas de ruta
    this.routeLines.forEach(routeLine => {
      if(routeLine.CardCode === customerForRoute.CardCode)
      {
        let loc: Location = locations.find(x => Number(x.Long) == routeLine.Longitude && Number(x.Lat) == routeLine.Latitude && x.LineNum === routeLine.AddressLineNum);

        if(loc) customerForRoute.Locations.push({ ...loc, added: false, VisitingTime: routeLine.VisitingTime, VisitEndTime: routeLine.VisitEndTime });
      }
    });
    // Agregamos las demas lineas de ruta que no son parte de las ubicaciones
    locations.filter(loc => !customerForRoute.Locations.some(x => x.Long == loc.Long && x.Lat == loc.Lat && loc.LineNum === x.LineNum)).forEach((loc, index) => {
      customerForRoute.Locations.push({ ...loc, added: false });
    });
  }

  addNewCustomerToRoute(btnCloseModalLocation: any) {
    
    //Se agrega el customer si no esta en los puntos de ruta
    if (!this.customersInRoute.some((x) => x === this.customerAddRoute)) 
    {
      if (this.customerAddRoute.Locations.some((x) => x.added)) 
      {
        this.customersInRoute.push(this.customerAddRoute);

        this.routeLines = [];

        this.customersInRoute.forEach(customer => this.addRouteLines(customer));
      }
    } 
    else 
    {
      // Se debe corregir que al eliminar las lineas se reordene como debe ser o que se reordene en la vista 
      // this.deleteAllCustomerLocations(this.customerAddRoute.CardCode);
      // this.addRouteLines(this.customerAddRoute);

      // Reagregamos todas las lineas para mantener el orden
      this.routeLines = [];

      this.customersInRoute.forEach(customer => this.addRouteLines(customer));
    }
    
    this.customerAddRoute = undefined;

    btnCloseModalLocation.click();

    if(this.visitingTimeIsWrong)
    {
      this.alertService.errorAlert("Se eliminaron algunas ubicaciones del cliente. Detalle: La hora de visita inicial no puede ser mayor a la hora de visita final.");

      this.visitingTimeIsWrong = false;
    }
  }

  addRouteLines(customerAddRoute: CustomerLocations) {
    // Se reordenan las lineas no visibles en la parte superior para que no molesten la funcion de dragado
    customerAddRoute.Locations.sort((a, b) => a.added ? 1 : -1);
    
    let isCustomerToAdd: boolean = false;

    customerAddRoute.Locations.forEach((loc: LocationForRoute) => {
      if (loc.added) 
      {
        //Se verifica que el BP que se esta iterando sea el que se va a agregar
        if(this.customerAddRoute && this.customerAddRoute.CardCode === customerAddRoute.CardCode)
        {
          //Indica si es el cliente a agregar
          isCustomerToAdd = true;

          let visitingTime: string = this.visitHourForm.get(`${loc.LineNum}visitingTime`).value;

          let visitEndTime: string = this.visitHourForm.get(`${loc.LineNum}visitEndTime`).value;

          loc.VisitingTime = visitingTime;
          
          loc.VisitEndTime = visitEndTime;
          
        }
         
        let routeLine: RouteLinesMobileModel = {
          Address: loc.Name,
          CardCode: customerAddRoute.CardCode,
          CardName: customerAddRoute.CardName,
          Id: 0,
          Latitude: Number(loc.Lat),
          Longitude: Number(loc.Long),
          Status: RouteStatus.Active,
          idRoute: this.routeId,
          AddressLineId: loc.Id,
          CheckStatus: CheckType.NoApply,
          LastUpdate: new Date(),
          AddressType: loc.Type ? loc.Type : RouteTypes.None,
          VisitingTime: loc.VisitingTime,
          VisitEndTime: loc.VisitEndTime,
          AddressLineNum: loc.LineNum
        };

        this.routeLines.push(routeLine);
      }
    });

    //Elimino todos los controls de formulario, para que no se sumen con los de otros clientes
    if(isCustomerToAdd) this.DeleteVisitingHourControls();
  }

  deleteAllCustomerLocations(cardCode: string) {
    this.routeLines = this.routeLines.filter((x) => x.CardCode !== cardCode);
  }

  deleteCustomerLocation(location: LocationForRoute, customerRoute: CustomerLocations) {
    location.added = false;

    let locationIndexInRouteLines = this.routeLines.findIndex((x) => x.Latitude == Number(location.Lat) && x.Longitude == Number(location.Long) && x.CardCode === customerRoute.CardCode && x.AddressLineNum === location.LineNum);

    if(locationIndexInRouteLines >= 0) this.routeLines.splice(locationIndexInRouteLines, 1);

    this.customersInRoute = this.customersInRoute.filter((x) => x.Locations.some((y) => y.added));

    //Se reordenan las lineas no visibles en la parte superior para que no molesten la funcion de dragado
    customerRoute.Locations.sort((a, b) => a.added ? 1 : -1);
  }

  deleteCustomerFromRoute(customerIndex: number) {
    this.customersInRoute.splice(customerIndex, 1);
  }

  setLocationInEdition(location: LocationForRoute, cardCode: string) {
    if (location === this.locationInEdition) {
      this.locationInEdition = undefined;
      this.customerInEdition = "";
    } else {
      this.locationInEdition = location;
      this.customerInEdition = cardCode;
    }
  }

  showModalCustomerLocations(customer: any) {
    this.customerAddRoute = customer;

    this.GenerateVisitingTimeControls(this.customerAddRoute);

    document.getElementById("open-locations-modal").click();
  }

  getLocationsOfCustomersInRoute() {
    this.blockUI.start();
    this.customersInRoute.forEach(async (x, index) => {
      this.getCustomerLocationsAndCheckStatus(
        x,
        index,
        this.customersInRoute.length
      );
    });
  }
 
  getCustomersInRoute(routeLines: RouteLinesMobileModel[]) {
    if (routeLines && routeLines.length > 0) {
      routeLines.forEach((rll) => {
        if (!this.customersInRoute.some((x) => x.CardCode === rll.CardCode)) {
          let bpModel: any = this.bpList.find((x) => x.CardCode === rll.CardCode);

          let customerInRoute: CustomerLocations = {
            ...bpModel,
            Locations: [],
            isSelected: false,
          };

          this.customersInRoute.push(customerInRoute);
        }
      });
      this.getLocationsOfCustomersInRoute();
    }
  }

  checkLocationStatusWithRouteLines(customer: CustomerLocations) {
    if (customer.Locations && customer.Locations.length > 0) {
      customer.Locations.forEach((location) => {
        if (this.routeLines.some((line) => line.CardCode === customer.CardCode && line.AddressLineNum === location.LineNum && line.Latitude == Number(location.Lat) && line.Longitude == Number(location.Long))) {
          location.added = true;
        }
      });
      // reordenamos para evitar problemas con el dragado
      customer.Locations.sort((a, b) => a.added ? 1 : -1);
    }
  }

  getCustomerLocationsAndCheckStatus(
    customer: CustomerLocations,
    index: number,
    customersLenght: number
  ) {
    this.bpService.getCustomerLocations(customer.CardCode).subscribe(
      (locResponse: LocationsResponse) => {
        if (locResponse.result) {
          this.convertLocationsToLocationForRoute(
            locResponse.Locations,
            customer
          );
          this.checkLocationStatusWithRouteLines(customer);
        } else {
          this.alertService.warningAlert(
            `El cliente ${customer.CardCode} no posee ubicaciones`
          );
        }
        if (index === customersLenght - 1) {
          this.blockUI.stop();
        }
      },
      (error) => {
        if (index === customersLenght - 1) {
          this.blockUI.stop();
        }
        this.alertService.errorAlert(error);
        console.log(error);
      }
    );
  }

  zoomToMarker(line: RouteLinesMobileModel) {
    this.lat = Number(line.Latitude);
    this.lng = Number(line.Longitude);
    this.map.setCenter({ lat: this.lat, lng: this.lng });

    const zoomInterval = setInterval(() => {
      this.mapZoom++;
      if (this.mapZoom === 20) {
        clearInterval(zoomInterval);
      }
    }, 200);
  }

  haveAddedLinesToRoute() {
    if (!this.customerAddRoute || !this.customerAddRoute.Locations) return;
    return this.customerAddRoute.Locations.find((x) => x.added);
  }

  searchRouteLineByLocation(
    location: LocationForRoute,
    customer: CustomerLocations
  ) {
    if (!location || !customer || !customer.Locations) return;
    return this.routeLines.find(
      (x) => x.Longitude == Number(location.Long) && x.Latitude == Number(location.Lat) && x.CardCode == customer.CardCode && x.AddressLineNum === location.LineNum //x.AddressLineId == location.Id && x.CardCode == customer.CardCode
    );
  }

  routeLineIsActive(location: LocationForRoute, customer: CustomerLocations) {
    let routeLine = this.searchRouteLineByLocation(location, customer);
    if (!routeLine) return;
    return routeLine.Status.toString() == RouteStatus.Active.toString()
      ? true
      : false;
  }

  onClickDisableEnableRouteLine(
    location: LocationForRoute,
    customer: CustomerLocations
  ) {
    let routeLine = this.searchRouteLineByLocation(location, customer);
    if (!routeLine) return;

    if (routeLine.Status == RouteStatus.Active) {
      routeLine.Status = 1;
    } else {
      routeLine.Status = 2;
    }
  }

  mapReady(map) {
    this.map = map;
  }

  validateRouteTypeByUserPerm() {
    let userPerm = this.userService.getRouteAdminType();
    if (userPerm === 0) return;

    if (userPerm == Geoconfigs.RouteAdmin) {
      this.routeForm.patchValue({ type: RouteTypes.Bill });
      this.routeForm.get("type").enable();
    } else {
      this.routeForm.patchValue({ type: userPerm });
    }
  }

  onChangeRouteType() {
    if (this.customersInRoute && this.customersInRoute.length > 0) {
      this.alertService.infoInfoAlert(
        "Al cambiar el tipo de ruta, se eliminan los clientes agregados"
      );

      this.customersInRoute = [];
      this.routeLines = [];
    }
  }

  DropCustomer(event: CdkDragDrop<CustomerLocations[]>) {
    moveItemInArray(this.customersInRoute, event.previousIndex, event.currentIndex);
    // Limpiamos las lineas
    this.routeLines = [];
    // Reagregamos las lineas para reordenarlas
    this.customersInRoute.forEach(customer => this.addRouteLines(customer));
  }

  DropLocation(event: CdkDragDrop<LocationForRoute[]>, customerLocations: LocationForRoute[]) {
    moveItemInArray(customerLocations, event.previousIndex, event.currentIndex);
    // Limpiamos las lineas
    this.routeLines = [];
    // Reagregamos las lineas para reordenarlas
    this.customersInRoute.forEach(customer => this.addRouteLines(customer));
  }

  EstimatedRoute(): void {
    this.jsonCalculations = '[]';
    this.routeCalculationService.CalculateRoute(this.routeLines).then(result => {
      this.estimatedDistance = 0;
      this.estimatedDuration = 0;
      result.forEach(matrix => {
        if(matrix.rows){
          matrix.rows.forEach(row => {
            row.elements.forEach(element => {
              if(element.status === 'OK'){
                this.estimatedDistance += element.distance.value;
                this.estimatedDuration += element.duration.value;
              }
            });
          });
        }
      });
      this.jsonCalculations = JSON.stringify(result);
      this.alertService.informationAlert('Calculos estimados', `<p>Distancia: ${this.decimalPipe.transform(this.estimatedDistance/1000, '1.2-2')} Km<p><p>Duración: ${this.decimalPipe.transform(this.estimatedDuration/60/60, '1.2-2')} horas<p>`, 'Continuar')
    }).catch(error => {
      this.alertService.errorAlert(error);
    });
  }

  /**
   * Agrega o quita el punto de ruta cuando es seleccionado y si el parametro `_inputChange` es `false`, en caso contrario valida que la hora inicial no sea mayor a la hora final
   * @param _location Dirección para verificar sus horas del punto de ruta
   * @param _inputChange Indica si el metodo es llamado por el cambio de los inputs del punto de ruta
   */
  onRoutePointChange(_location: LocationForRoute, _inputChange: boolean = false): void{
    //Verifico que el evento onChange no sea del input para agregar o quitar el punto de ruta
    if(!_inputChange) _location.added = !_location.added;

    let visitingTime: string = this.visitHourForm.get(`${_location.LineNum}visitingTime`).value;

    let visitEndTime: string = this.visitHourForm.get(`${_location.LineNum}visitEndTime`).value;

    if(!this.CheckRoutePointVisitingHourTime(visitingTime, visitEndTime) && _location.added)
    {
      this.alertService.errorAlert("La hora de inicio no puede ser mayor a la hora final");

      _location.added = false;
    }
  }

  /**
   * Verifica si la hora de inicio del punto de ruta es mayor a la hora final del punto de ruta
   * @param _visitingTime Hora de inicio del punto de ruta
   * @param _visitEndTime Hora final del punto de ruta
   * @returns Devuelve `true` si la hora de inicial es menor a la hora final, en caso contrario devuelve `false`
   */
  CheckRoutePointVisitingHourTime(_visitingTime:string, _visitEndTime: string): boolean{
    let visitingTime: string[] = _visitingTime.split(':');

    let visitEndTime: string[] = _visitEndTime.split(':');

    let visitingTimeMinutes: number = (parseInt(visitingTime[0]) * 60) + parseInt(visitingTime[1]);

    let visitEndTimeMinutes: number = (parseInt(visitEndTime[0]) * 60) + parseInt(visitEndTime[1]);

    if(visitingTimeMinutes > visitEndTimeMinutes)
    {
      return false;
    }

    return true;
  }

  /**
   * Crea un `FormControl` por cada ubicación del cliente en `visitHourForm`
   * @param _customer Cliente con ubicaciones para generar los FormControls en `visitHourForm`
   */
  GenerateVisitingTimeControls(_customer: CustomerLocations): void{
    _customer.Locations.forEach(l => {
      //Hora por defecto de inicio de visita
      let visitingTime: string = "00:00";

      //Hora por defecto de final de visita
      let visitEndTime: string = "23:59";

      if(l.VisitingTime) visitingTime = l.VisitingTime;

      if(l.VisitEndTime) visitEndTime = l.VisitEndTime;

      this.visitHourForm.addControl(`${l.LineNum}visitingTime`, new FormControl(visitingTime));

      this.visitHourForm.addControl(`${l.LineNum}visitEndTime`, new FormControl(visitEndTime));
    });
  }

  /**
   * Elimina todos los FormControls del FormGroup `visitHourForm`
   */
  DeleteVisitingHourControls(): void{
    for (const control in this.visitHourForm.controls) {
      this.visitHourForm.removeControl(control);
    }
  }
}
