import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import { IGlobalSearch, ILogMobile } from 'src/app/models';
import { AlertService } from 'src/app/services';
import { LogsMobileService } from 'src/app/services/logs-mobile.service';
import { LogMobileTypes } from '../../models/constantes';

@Component({
  selector: 'app-event-viewer',
  templateUrl: './event-viewer.component.html',
  styleUrls: ['./event-viewer.component.css']
})
export class EventViewerComponent implements OnInit {
  // varbox
  @BlockUI() blockUI: NgBlockUI;
  logsMobile: ILogMobile[];
  pagedLogsMobile: ILogMobile[];
  logMobileTypes = LogMobileTypes;
  searchForm: FormGroup;
  activeLogMobile: ILogMobile;
  totalRows: number;
  currentPage: number;
  rowsByPage: number;
  
  constructor(
    private logsMobileService: LogsMobileService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private alertService: AlertService
  ) { }
  
  ngOnInit(): void {
    this.InitVariables();
    this.ResetSearchForm();
    this.LoadLogsMobile();
  }
  
  InitVariables(){
    this.totalRows = 1;
    this.rowsByPage = 10;
    this.currentPage = 1;
    this.logsMobile = [];
    this.pagedLogsMobile = [];
  }
  
  ResetSearchForm(): void{
    this.searchForm = this.formBuilder.group({
      UserMapId: [-1],
      Search: [''],
      Since: ['', Validators.required],
      Until: ['', Validators.required],
      Status: [-1],
      ObjectType: [-1]
    });
    this.searchForm.controls['Since'].setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
    this.searchForm.controls['Until'].setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en'));
  }
  
  LoadLogsMobile(): void{
    let globalSearch = this.searchForm.value as IGlobalSearch;
    let offset = (this.currentPage - 1) * this.rowsByPage;
    let rows = this.rowsByPage;
    this.blockUI.start(`Procesando, espere por favor`);
    this.logsMobileService.GetPagedLogsMobile(globalSearch, offset, rows).pipe(finalize(() => this.blockUI.stop())).subscribe(req => {
      try {
        if (req.result) {
          this.totalRows = req.Data.TotalCount;
          this.pagedLogsMobile = req.Data.LogsMobile;
          if (this.pagedLogsMobile.length === 0) {
            this.alertService.infoInfoAlert(`No se encontraron registros`);
          }
        } else {
          this.alertService.errorAlert(req.errorInfo.Message);
        }
  
      }
      catch (error) {
        console.log(error);
      }
    }, error => {
      this.alertService.errorAlert(`${error}`);
      console.log(error);
    });
  }

  GetLogMobileType(type: number): any {
    return this.logMobileTypes.find(x => x.Value == type);
  }
  
  ShowErrorModal(content: NgbModalRef, log: ILogMobile): void {
    this.activeLogMobile = log;
    this.modalService.open(content, { size: 'lg' });
  }
  
  PageChange(): void {
    this.LoadLogsMobile();
  }
  
  ResultsChange(): void {
    this.currentPage = 1;
    this.LoadLogsMobile();
  }

}
