import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService, AlertService } from '../../../services/index';
import { Router } from '@angular/router';
import { BaseResponse, UsersResponse } from './../../../models/index';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-users-conf',
  templateUrl: './users-conf.component.html',
  styleUrls: ['./users-conf.component.css']
})

export class UsersConfComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;

  constructor(private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private fbg: FormBuilder,
    private router: Router,
    private uService: UserService) { }

  userGroup: FormGroup;
  userId: string;
  btnSendInfo: string; // cambia el titulo de el boton de envio
  disable: boolean;
  user: any;
  title: string;
  isEditing: boolean;

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.userGroup = this.fbg.group({
      UserName: ['', [Validators.required]],
      userPass: ['', [Validators.required]],
      userEmail: ['', [Validators.required, Validators.minLength(2), Validators.pattern('[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      userPassEmail: ['', [Validators.required]],
      Active: [''],
      Scheduling: ['']
    });

    this.userId = this.activatedRoute.snapshot.paramMap.get('userId').toString();
    if (this.userId === '0') {
      this.title = 'Creación de usuario';
    } else {
      this.title = 'Edición de usuario';
    }
    this.disable = false;
    if (this.userId !== '0') {
      this.disable = true;
      this.isEditing = true;
      this.btnSendInfo = 'Actualizar';
      this.chargeUser();
    } 
    else
    { 
      this.isEditing = false;
      this.btnSendInfo = 'Crear'; 
    }
  }

  get f() { return this.userGroup.controls; }

  onSubmitCreate() {

    if (this.userGroup.invalid && !this.ValidateForm()) {
      return;
    }

    this.blockUI.start();
    this.uService.sendUserInfo(this.userGroup, this.userId)
      .subscribe((data: BaseResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.alertService.successInfoAlert('Proceso finalizado exitosamente');
          setTimeout(() => {
            this.router.navigate(['UserAdd']);
          }, 500);
        } else {
          this.alertService.warningAlert(`${data.errorInfo.Message}`);
        }
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorAlert(`${error}`);
      });
  }

  chargeUser() {
    this.blockUI.start();
    this.uService.getUsers()
      .subscribe((data: UsersResponse) => {
        this.blockUI.stop();
        if (data.result) {
          data.userList.forEach(element => {
            if (element.userId === this.userId) {
              this.user = element;
            }
          });
          this.charge();
        } else {
          this.alertService.warningAlert(`${data.errorInfo.Message}`);
        }
      }, (error: any) => {
        this.blockUI.stop();
        this.alertService.errorAlert(`${error}`);
      });
  }

  charge() {
    this.userGroup.controls.UserName.setValue(this.user.userName);
    this.userGroup.controls.userEmail.setValue(this.user.userEmail);
    this.userGroup.controls.Active.setValue(this.user.Active);
    this.userGroup.controls.Scheduling.setValue(this.user.Scheduling);
  }

  ValidateForm(): boolean{
    if(!this.isEditing){
      if(this.userGroup.get("userPass").value == "" ||
         this.userGroup.get("userPassEmail").value == ""){
          return false;
      }
    }
    return true;
  }

  VerifyUserEmailDomain(userEmail: string): Boolean {
    
    let pattern: RegExp = /@gmail.com$/g;
    
    return pattern.test(userEmail);
  }

}
