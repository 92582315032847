import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// MODELOS
import { AppConstants, SalesManResponse } from './../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SalesManService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) {
    console.log('SalesManService listo');
  }

  // metodo que obtiene los vendedores de la aplicacion
  getSalesMan() {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    return this.http.get<SalesManResponse>(`${AppConstants.apiUrl}api/SalesMan/GetSalesMan?UsrMapId=${TOKEN.userMappId}`,
      { headers }
    );
  }

}
