import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

// MODELOS
import {
  AppConstants, RoutesMobileModel, CreateRouteMobileResponse, BusinessPartnersModel,
  BaseResponse, RoutesResponse, RouteAssignFrontResponse, RouteLinesMobileModel,
  RouteAssignModel, WaypointChecksResponse, RoutesAssignStatusResponse, RouteLinesResponse, RoutesMobileWithStatus, CheckAttachments, UsersModelResponse, IFile
} from './../models/index';
import { StorageService } from './storage.service';
import { IPagedRoutesMobileWithStatus } from '../models/route';
import { ApiResponse } from '../models/responses';
import { Observable, Observer } from 'rxjs';
import { CalculationType } from '../enums/enums';
import { IRouteCalculationDetail } from '../models/i-route-calculation-detail';
import { IProcessedRoute } from '../models/i-processed-route';

// RUTAS

// COMPONENTES

// SERVICIOS

@Injectable({
  providedIn: 'root'
})
export class RouteService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  // metodo que crea una ruta para un cliente
  CreateRouteMobile(routeForm: FormGroup, estimatedDistance: number, estimatedDuration: number, userMappId: number, V_RouteLines: RouteLinesMobileModel[], routeId: number) {

    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    let route: RoutesMobileModel = {
      Id: routeId,
      Name: routeForm.controls.routeName.value,
      CreationDate: new Date(),
      ExpirationDate: new Date(routeForm.controls.ExpirationDate.value),
      FrequencyId: routeForm.controls.frequency.value,
      UserMappId: userMappId,
      DB: '',
      Status: 1,
      V_RouteLines: V_RouteLines,
      Type: routeForm.controls.type.value, 
      TotalEstimatedDistance: estimatedDistance,
      TotalEstimatedDuration: estimatedDuration
    };

    if (routeId > 0) {
      return this.http.post<CreateRouteMobileResponse>(`${AppConstants.apiUrl}api/Route/UpdateRouteMobile?userMappId=${userMappId}`,
        route,
        { headers });
    } else {
      return this.http.post<CreateRouteMobileResponse>(`${AppConstants.apiUrl}api/Route/CreateRouteMobile?userMappId=${userMappId}`,
        route,
        { headers });
    }

  }

  // metodo que crea la asignacion de la ruta para un usuario
  // CreateAssignRoute(assignForm: FormGroup, userMappId: number) {
  CreateAssignRoute(assignForm: FormGroup, routeId: number) {
    let displayDate = new Date();

    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    
    let userMappId: number = assignForm.value.user !== "" ? assignForm.value.user.split('-')[0].trim() : 0;

    let assign: RouteAssignModel = {
      Id: 0,
      CreationDate: displayDate,
      UserMappIdCreate: TOKEN.userMappId,
      UserMappIdUpdate: TOKEN.userMappId,
      UserMappId: userMappId
    };

    return this.http.post<BaseResponse>(`${AppConstants.apiUrl}api/Route/CreateAssignRoute?routeId=${routeId}`,
      assign,
      { headers });
  }

  // metodo que obtiene las rutas
  GetRoutes() {

    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<RoutesResponse>(`${AppConstants.apiUrl}api/Route/GetRoutes`,
      { headers }
    );

  }

  // metodo que obtiene las rutas
  GetRoutesAssigns() {

    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<RouteAssignFrontResponse>(`${AppConstants.apiUrl}api/Route/GetRoutesAssigns`,
      { headers }
    );

  }

  // metodo que crea la asignacion de la ruta para un usuario
  // CreateAssignRoute(assignForm: FormGroup, userMappId: number) {
  DeleteAssignRoute(AssignId: number) {

    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<BaseResponse>(`${AppConstants.apiUrl}api/Route/DeleteAssignRoute?AssignId=${AssignId}`,
      { headers });
  }

  // metodo que obtiene los waypoints y checks de la ruta selecionada
  GetWaypointChecks(idRoute: number, cardCode: string, userEmail: string, dateFrom, dateTo?) {

    if (!dateTo) {
      dateTo = new Date().toISOString();
    }

    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<WaypointChecksResponse>(`${AppConstants.apiUrl}api/Route/GetWaypointChecks?idRoute=${idRoute}&cardCode=${cardCode}&userEmail=${userEmail}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
      { headers }
    );
  }

  // Metodo para la obtencion de las rutas con el estado de si poseen alguna asignacion
  GetRoutesWithStatus(routeType: number) {

    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    let userMappId = 0;
    if (routeType === 0) {
      userMappId = Number(TOKEN.userMappId);
    }

    return this.http.get<RoutesAssignStatusResponse>(`${AppConstants.apiUrl}api/Route/GetRoutesWithStatus?routeType=${routeType}&userMappId=${userMappId}`,
      { headers }
    );
  }

  getRouteLines(idRoute: number) {

    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<RouteLinesResponse>(`${AppConstants.apiUrl}api/Route/GetRouteLines?routeId=${idRoute}`,
      { headers }
    );
  }

  putRouteAdministrators(route: RoutesMobileWithStatus) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    return this.http.put<BaseResponse>(`${AppConstants.apiUrl}api/Route/PutRouteAdministratos?userMappId=${Number(TOKEN.userMappId)}`, route, { headers });
  }

  GetChecksNotInRoute(date: string, routeAssignId: number, customer: string) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<WaypointChecksResponse>(`${AppConstants.apiUrl}api/Route/GetChecksNotInRoute?date=${date}&routeAssignId=${routeAssignId}&customer=${customer}`, { headers });
  }

  DownloadBlob(blobName: string) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<CheckAttachments>(`${AppConstants.apiUrl}api/CheckIn/DownloadBlob?blob=${blobName}`, { headers });
  }

  GetUsersAssignedToRoute(routeId: number) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<UsersModelResponse>(`${AppConstants.apiUrl}api/Route/GetUsersAssignedToRoute?routeId=${routeId}`, { headers });
  }

  GetRoutesFilter(name: string, type: number, frequencyId: number, assignedUserId: number, offset: number, rows: number): Observable<ApiResponse<IPagedRoutesMobileWithStatus>> {//api/Route/GetRoutesFilter//
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<ApiResponse<IPagedRoutesMobileWithStatus>>(`${AppConstants.apiUrl}api/Route/GetRoutesFilter?name=${name}&type=${type}&frequencyId=${frequencyId}&assignedUserId=${assignedUserId}&offset=${offset}&rows=${rows}`, { headers });
  }

  CloseRoute(routeId: number, closeDetail: string): Observable<BaseResponse> {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<BaseResponse>(`${AppConstants.apiUrl}api/Route/CloseRoute?routeId=${routeId}&closeDetail=${closeDetail}`, { headers });
  }

  PutRouteCalculationDetails(routeId: number, type: CalculationType.CALCULATED | CalculationType.ESTIMATED, distance: number, duration: number, json: string): Observable<BaseResponse> {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.post<BaseResponse>(
      `${AppConstants.apiUrl}api/Route/PutRouteCalculationDetails?routeId=${routeId}&type=${type}&distance=${distance}&duration=${duration}`, JSON.stringify(json),
      { headers }
    );
  }

  GetRouteCalculationDetails(routeId: number): Observable<ApiResponse<IRouteCalculationDetail[]>> {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<ApiResponse<IRouteCalculationDetail[]>>(`${AppConstants.apiUrl}api/Route/GetRouteCalculationDetails?routeId=${routeId}`, { headers });
  }

  DownloadRouteMassiveTemplateFile(): Observable<ApiResponse<IFile>>{
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<ApiResponse<IFile>>(`${AppConstants.apiUrl}api/Route/DownloadRouteMassiveTemplateFile`, { headers });
  }

  UploadRoutesFile(_formData: FormData): Observable<ApiResponse<IProcessedRoute[]>> {
    return this.http.post <ApiResponse<IProcessedRoute[]>> (`${AppConstants.apiUrl}api/Route/UploadRoutesFile`, _formData);
  }

  CreateRoutes(processedRoutes: IProcessedRoute[]): Observable<ApiResponse<IProcessedRoute[]>> {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.post<ApiResponse<IProcessedRoute[]>>(
      `${AppConstants.apiUrl}api/Route/CreateRoutesFromFile`, 
      processedRoutes,
      { headers }
    );
  }

}
