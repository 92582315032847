import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Router } from '@angular/router';

// MODELOS
import { Globals } from './../../../globals';
import { FrequencyListResponse, Week } from './../../../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { AlertService, FrequencyService } from './../../../services/index';

@Component({
  selector: 'app-frequency-view',
  templateUrl: './frequency-view.component.html',
  styleUrls: ['./frequency-view.component.css']
})
export class FrequencyViewComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  frequencyList: any[] = [];
  title = 'Frecuencias';

  // PERMISOS
  G_CreateFrequency: boolean; // permiso para la creacion de rutas
  G_EditFrequency: boolean; // permiso para la edicion de rutas

  constructor(private alertService: AlertService,
    private router: Router,
    private freqService: FrequencyService,
    private globals: Globals,
  ) {
    this.G_CreateFrequency = this.globals.generalWebPermList.some(x => x.Name === 'G_CreateFrequency');
    this.G_EditFrequency = this.globals.generalWebPermList.some(x => x.Name === 'G_EditFrequency');
  }

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.chargeFreqList();
  }

  // carga la lista de frecuansias que estan registradas en BD
  chargeFreqList() {
    this.blockUI.start();
    this.freqService.GetFrequencyList().subscribe((data: FrequencyListResponse) => {
      this.blockUI.stop();
      if (data.result) {
        this.frequencyList = data.frequencyList;
      } else {
        this.alertService.warningAlert(`${data.errorInfo.Message}`);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorAlert(`${error}`);
    });
  }

  // envia a la pagina de configuracion a crear o a editar
  confPage(id) {
    this.router.navigate(['frequencyConf/' + id]);
  }

  getDaysName(daysValues: string) {
    let daysValuesArray = daysValues.split('.');
    let daysNames: string = '';

    daysValuesArray.forEach((day, index) => {
      let dayObject = Week.find(x => x.Value.toString() == day);

      if (dayObject) daysNames += `${dayObject.Name}`;
      if (index != daysValuesArray.length - 1) {
        daysNames += ', ';
      }
    });

    return daysNames;
  }
}
