import { Component, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { RolsModel } from 'src/app/models';
import { RolService, AlertService } from 'src/app/services';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rols',
  templateUrl: './rols.component.html',
  styleUrls: ['./rols.component.css']
})
export class RolsComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  rols: RolsModel[];
  rolInEdition: RolsModel;
  rolModal: NgbModalRef;

  constructor(private rolService: RolService,
    private alertService: AlertService,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.getRols();
    this.rolInEdition = null;
  }

  getRols() {
    this.rols = [];

    this.rolService.getRoles().subscribe((data) => {
      if (data.result) {
        this.rols = data.RolsList;
      } else {
        this.alertService.errorAlert(`${data.errorInfo.Message}`);
      }
    }, error => {
      this.alertService.errorAlert(`${error}`);
    });
  }

  onClickRolModal(rolModal: any) {
    if (!this.rolInEdition) {
      this.rolInEdition = {
        Active: true,
        Id: 0,
        Name: null
      };
    }

    this.rolModal = this.modalService.open(rolModal, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
    this.rolModal.result.then(() => {
      this.rolInEdition = null;
    });
  }

  onClickCreateRol() {
    this.blockUI.start();
    this.rolService.handlePostOrPutRol(this.rolInEdition).subscribe(response => {
      this.blockUI.stop();
      if (response.result) {
        this.alertService.successInfoAlert('Proceso finalizado exitosamente');
        this.initializePage();
        this.onClickDismissModal();
      } else {
        this.alertService.errorAlert(response.errorInfo.Message);
      }
    }, err => {
      this.blockUI.stop();
      this.alertService.errorAlert(err);
    });
  }

  onClickEditRol(rolModal: any, rol: RolsModel) {
    this.rolInEdition = { ...rol };

    this.rolModal = this.modalService.open(rolModal, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
    this.rolModal.result.then(() => this.rolInEdition = null);
  }

  onClickDismissModal() {
    this.rolModal.close({});
  }


}
