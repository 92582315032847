import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// SERVICES
import { StorageService } from './storage.service';

// MODELS
import { SAPSeriesResponse, AppConstants, SeriesResponse, SerieWithFESerie, BaseResponse, SeriesModel } from 'src/app/models';

@Injectable({
  providedIn: 'root'
})
export class SeriesService {


  constructor(private http: HttpClient,
    private storage: StorageService) { }

  getSAPSeries() {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    return this.http.get<SAPSeriesResponse>(`${AppConstants.apiUrl}api/NumberingSeries/GetSAPSeries?userMappId=${TOKEN.userMappId}`, { headers });
  }

  getSeriesByUser(userMappId: number) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    return this.http.get<SeriesResponse>(`${AppConstants.apiUrl}api/Users/GetAllSeriesByUser?userMappId=${userMappId}&lang=es`, { headers });
  }

  getAllSeries() {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    return this.http.get<SeriesResponse>(`${AppConstants.apiUrl}api/NumberingSeries/GetAllSeries?userMappId=${TOKEN.userMappId}`, { headers });
  }

  handlePostOrPutSerie(newSerie: SerieWithFESerie) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.post<BaseResponse>(`${AppConstants.apiUrl}api/NumberingSeries/HandlePostOrPut?userMappId=${Number(TOKEN.userMappId)}`, newSerie, { headers });
  }

  assignSerie(userMappId: number, serieId: number, share: boolean) { //
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.post<BaseResponse>(`${AppConstants.apiUrl}api/NumberingSeries/AssignSerie?userMappId=${userMappId}&serieId=${serieId}&share=${share}`, {}, { headers });
  }

  deleteAssignation(serieId: number, userMappId: number) {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.delete<BaseResponse>(`${AppConstants.apiUrl}api/NumberingSeries/DeleteSerieAssignation?serieId=${serieId}&userMappId=${userMappId}`, { headers });
  }

}
