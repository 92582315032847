import { Component, OnInit } from '@angular/core';
import { PermissionsService } from '../../../services/permissions.service';
import { PermissionsSelectedModel } from 'src/app/models';
import { AlertService } from 'src/app/services';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-permissions-crud',
  templateUrl: './permissions-crud.component.html',
  styleUrls: ['./permissions-crud.component.css']
})
export class PermissionsCrudComponent implements OnInit {

  permissionsList: PermissionsSelectedModel[];
  @BlockUI() blockUI: NgBlockUI;
  permModal: NgbModalRef;
  permDescription: string;
  permName: string;
  permId: number;
  constructor(private permissionsService: PermissionsService, private alertService: AlertService,
    private modalService: NgbModal) { }

  ngOnInit() {
    this.blockUI.start();
    this.permissionsService.getPerms().subscribe(response => {
      this.blockUI.stop();
      if (response.result) {
        this.permissionsList = response.PermissionsList;
      } else {
        this.alertService.errorAlert(response.errorInfo.Message);
      }
    });
  }

  onClickDismissModal() {
    this.permModal.close();
  }

  onClickEditperm(_id: number, modalContent: any): void {
    this.permDescription = this.permissionsList.filter(x => x.Id === _id)[0].Description;
    this.permName = this.permissionsList.filter(x => x.Id === _id)[0].Name;
    this.permId = _id;
    this.permModal = this.modalService.open(modalContent, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static' });
  }

  // Actualiza la descripcion del permiso
  savePerm(): void {
    this.blockUI.start();
    this.permissionsService.UpdatePermission(this.permId, this.permDescription).subscribe(response => {
      this.blockUI.stop();
      this.permModal.close();
      if (response.result) {
        this.permissionsList.forEach(x => {
          if (x.Id === this.permId) {
            x.Description = this.permDescription;
          }
        });
        this.alertService.successInfoAlert('Proceso finalizado exitosamente');
      } else {
        this.alertService.errorAlert(`Ocurrió un error actualizando el permiso ${response.errorInfo.Message}`);
      }
    });
  }
}
