import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';

// MODELOS
import { AppConstants, PromotionsResponse, BaseResponse } from './../models/index';
import { PromotionsModel } from '../models/promotions';
import { StorageService } from './storage.service';

// RUTAS

// COMPONENTES

// SERVICIOS

@Injectable({
  providedIn: 'root'
})
export class PromotionsService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  // metodo que obtiene las promociones
  GetPromotions(userMappId: number) {

    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<PromotionsResponse>(`${AppConstants.apiUrl}api/Promotions/GetPromotions?userMappId=${userMappId}&lang=es`,
      { headers }
    );

  }

  // metodo que crea/modifica las promociones
  CreateUpdatePromotions(promotionForm: FormGroup, userMappId: number, promotionId: number) {

    let promotion: PromotionsModel = {
      Id: promotionId,
      Rule: promotionForm.value.Rule,
      Quantity: promotionForm.value.Quantity,
      UnitMeasurement: promotionForm.value.UnitMeasurement,
      ItemCode: promotionForm.value.ItemCode,
      Discount: promotionForm.value.Discount,
      DiscountType: promotionForm.value.DiscountType,
      Sequence: promotionForm.value.Sequence,
      UseDescripPromo: promotionForm.value.UseDescripPromo,
      DescripPromo: promotionForm.value.DescripPromo,
      CompanyId: promotionForm.value.CompanyId
    };
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    return this.http.post<BaseResponse>(`${AppConstants.apiUrl}api/Promotions/CreateUpdatePromotions?userMappId=${userMappId}`,
      promotion,
      { headers }
    );

  }


}
