import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor() {}

  downloadFile(
    base64File: string,
    fileName: string,
    blobType: string,
    fileExtension: string
  ) {
    let report = new Blob([this.stringToArrayBuffer(atob(base64File))], {
      type: blobType,
    });

    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(report);
    link.download = `${fileName}.${fileExtension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private stringToArrayBuffer(toConvert: string) {
    let buf = new ArrayBuffer(toConvert.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i != toConvert.length; ++i)
      view[i] = toConvert.charCodeAt(i) & 0xff;

    return buf;
  }

  isValidFile(file: File, validExtensions: string[]) {
    let fileExtension = this.getFileExtension(file);

    return validExtensions.some((x) => x === fileExtension);
  }

  getFileExtension(file: File): string {
    return file.name.split(".")[file.name.split(".").length - 1].toLowerCase();
  }
}
