import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from './../services/authentication.service';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardCompanyConfigService implements CanActivate {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private globals: Globals
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUserValue;

    let compId = route.params.companyId;

    let havePerm: boolean;

    if (compId === '0') { // creacion
      havePerm = this.globals.generalWebPermList.some((x) => {
        return x.Name === 'G_CreateCompany';
      });
    } else { // edicion
      havePerm = this.globals.generalWebPermList.some((x) => {
        return x.Name === 'G_EditCompany';
      });
    }

    let expires = new Date(currentUser.ExpireTime);
    let today = new Date();

    if (currentUser && (today <= expires)) { // posee token y el mismo aun no expira
      if (havePerm) { // tiene permiso para ingresar a la pagina de ruta
        return true;
      } else { // no tiene permiso para ingresar a la pagina de ruta
        this.router.navigate(['/home'], { queryParams: { returnUrl: state.url } });
        return false;
      }
    }

    this.authenticationService.logout();
    return false;
  }
}
