import { Component, OnInit, ViewChild } from "@angular/core";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  NgbTypeahead,
  NgbModal,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { Subject, Observable, merge } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from "rxjs/operators";

// SERVICES
import { SeriesService, AlertService, UserService } from "src/app/services";

// MODELS
import {
  SeriesModel,
  SAPSerie,
  UserRouteModel,
  SerieWithFESerie,
  DocumentSubtypes,
} from "src/app/models";

// ENUMS
import { DocumentType } from "src/app/enums";
import { NumberingSeries } from "src/app/enums/enums";

@Component({
  selector: "app-numbering-series",
  templateUrl: "./numbering-series.component.html",
  styleUrls: ["./numbering-series.component.css"],
})
export class NumberingSeriesComponent implements OnInit {
  // varbox
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild("typeaheadSerieNumber") typeaheadSerieNumber: NgbTypeahead;
  @ViewChild("ngbTabset") tabset;
  assignationModal: NgbModalRef;
  focus$: Subject<string>;
  click$: Subject<string>;
  seriesByUser: SeriesModel[];
  series: SeriesModel[];
  SAPSeries: SAPSerie[];
  users: UserRouteModel[];
  userIdToSearchSeries: number;
  formSerieSAP: FormGroup;
  formSerieFE: FormGroup;
  formSerieAssign: FormGroup;
  serieInEdition: SerieWithFESerie;
  useFESerie: boolean;
  documentSubtype = DocumentSubtypes;
  pagedSeries: SeriesModel[];
  rowsByPage: number;
  currentPage: number;

  constructor(
    private seriesService: SeriesService,
    private alertService: AlertService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal
  ) {
    this.click$ = new Subject<string>();
    this.focus$ = new Subject<string>();
  }

  ngOnInit() {
    this.initComponent();
  }

  initComponent() {
    this.series = [];
    this.rowsByPage = 10;
    this.currentPage = 1;
    this.pagedSeries = [];
    this.serieInEdition = null;
    this.useFESerie = false;
    this.tabset.activeId = "tabList";

    this.formSerieFE = this.formBuilder.group({
      Name: ["", Validators.required],
      Sucursal: ["", Validators.required],
      Terminal: ["", Validators.required],
      Active: [true, Validators.required],
      NextNumber: [0, Validators.required],
    });

    this.formSerieSAP = this.formBuilder.group({
      Name: ["", Validators.required],
      DocType: [13, Validators.required],
      Active: [true, Validators.required],
      SerieSAP: ["", Validators.required],
      Type: [3, Validators.required],
      Share: [1, Validators.required],
      GenerationType: [1, Validators.required],
      NextNumber: [0],
    });

    this.formSerieAssign = this.formBuilder.group({
      user: ["", Validators.required],
      serie: ["", Validators.required],
    });

    this.blockUI.start();
    this.getSAPSeries();
    this.getUsers();
    this.handlerInitialTasksFinished();
    this.getAllSeries();
  }

  resetFormValues(
    resetFormSAP: boolean,
    resetFormFE: boolean,
    resetFormAssign
  ) {
    if (resetFormFE) {
      this.formSerieFE.reset({
        Name: "",
        Sucursal: "",
        Terminal: "",
        Active: true,
        NextNumber: 0,
      });
    }
    if (resetFormSAP) {
      this.formSerieSAP.reset({
        Name: "",
        DocType: 13,
        Active: true,
        SerieSAP: "",
        Type: 3,
        Share: 1,
        GenerationType: 1,
        NextNumber: 0,
      });
    }
    if (resetFormAssign) {
      this.formSerieAssign.reset({
        user: "",
        serie: "",
      });
    }
  }

  getSAPSeries() {
    this.seriesService.getSAPSeries().subscribe(
      (response) => {
        if (response.result) this.SAPSeries = response.SAPSeries;
        else {
          this.SAPSeries = [];
          this.alertService.errorAlert(response.errorInfo.Message);
        }
      },
      (err) => {
        this.SAPSeries = [];
        this.alertService.errorAlert(err);
      }
    );
  }

  getSeriesByUser(userId: number) {
    this.blockUI.start();
    this.seriesService.getSeriesByUser(userId).subscribe(
      (response) => {
        this.blockUI.stop();
        if (response.result) {
          this.seriesByUser = response.Series;
          if(response.Series.length <= 0)
            this.alertService.errorAlert("El usuario no posee series de numeración asignadas");
        }
        else {
          this.seriesByUser = [];
          this.alertService.errorAlert(response.errorInfo.Message);
        }
      },
      (err) => {
        this.blockUI.stop();
        this.seriesByUser = [];
        this.alertService.errorAlert(err);
      }
    );
  }

  getAllSeries() {
    this.blockUI.start();
    this.seriesService.getAllSeries().subscribe(
      (response) => {
        this.blockUI.stop();
        if (response.result) {
          this.series = response.Series;
          this.PageChange();
        }
        else {
          this.series = [];
          this.PageChange();
          this.alertService.errorAlert(response.errorInfo.Message);
        }
      },
      (err) => {
        this.blockUI.stop();
        this.series = [];
        this.alertService.errorAlert(err);
      }
    );
  }

  getUsers() {
    this.userService.getUsersMobile().subscribe(
      (response) => {
        if (response.result) this.users = response.userList;
        else {
          this.users = [];
          this.alertService.errorAlert(response.errorInfo.Message);
        }
      },
      (err) => {
        this.users = [];
        this.alertService.errorAlert(err);
      }
    );
  }

  handlerInitialTasksFinished() {
    const interval = setInterval(() => {
      if (this.users && this.SAPSeries) {
        clearInterval(interval);
        this.blockUI.stop();
        this.userIdToSearchSeries =
          this.users.length > 0 ? this.users[0].userMappId : 0;
        this.getSeriesByUser(this.userIdToSearchSeries);
      }
    }, 1000);
  }

  onTabChange($event: any) {
    if ($event.nextId !== "tabCrud") {
      this.formSerieSAP.get("GenerationType").enable();
      this.formSerieSAP.get("Share").enable();
      this.formSerieSAP.get("Type").enable();
      this.serieInEdition = null;
      this.useFESerie = false;
      this.resetFormValues(true, true, false);
    }
  }

  onClickEditSerie(serie: SerieWithFESerie) {
    this.formSerieSAP.get("GenerationType").disable();
    this.formSerieSAP.get("Share").disable();
    this.formSerieSAP.get("Type").disable();
    this.serieInEdition = { ...serie };
    this.tabset.activeId = "tabCrud";
    this.patchForms(serie);
  }

  patchForms(serie: SerieWithFESerie) {
    this.formSerieSAP.reset({
      Name: serie.Name,
      DocType: this.getSerieObjectTypeInSAP(serie.DocType),
      Active: serie.Active,
      SerieSAP: `${serie.SerieSAP}-${this.getSAPSerieNameBySerieNumber(
        serie.SerieSAP
      )}`,
      Type: serie.Type,
      Share: Number(serie.Share),
      GenerationType: serie.GenerationType,
      NextNumber: serie.NextNumber,
    });

    if (serie.FESerieId) {
      this.useFESerie = true;

      this.formSerieFE.reset({
        Name: serie.FESerie.Name,
        Sucursal: serie.FESerie.Sucursal,
        Terminal: serie.FESerie.Terminal,
        Active: serie.FESerie.Active,
        NextNumber: serie.FESerie.NextNumber,
      });
    }
  }

  onChangeUserSelect($event) {
    this.userIdToSearchSeries = Number($event.target.value);
    this.getSeriesByUser(this.userIdToSearchSeries);
  }

  onClickAddFEInfo() {
    this.useFESerie = !this.useFESerie;
    this.formSerieSAP.get("Type").setValue(this.useFESerie ? 2 : 3);
  }

  searchSerieNumber = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.typeaheadSerieNumber.isPopupOpen())
    );
    const inputFocus$ = this.focus$;
    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        !term
          ? this.SAPSeries.filter(
              (x) =>
                x.ObjectCode ===
                (this.formSerieSAP.get("DocType").value == "14" ||
                this.formSerieSAP.get("DocType").value == "15" ||
                this.formSerieSAP.get("DocType").value == "16"
                  ? "13"
                  : this.formSerieSAP.get("DocType").value.toString())
            )
              .map((x) => `${x.Serie}-${x.Name}`)
              .slice(0, 10)
          : this.SAPSeries.filter(
              (x) =>
                x.ObjectCode ===
                  (this.formSerieSAP.get("DocType").value == "14" ||
                  this.formSerieSAP.get("DocType").value == "15" ||
                  this.formSerieSAP.get("DocType").value == "16"
                    ? "13"
                    : this.formSerieSAP.get("DocType").value.toString()) &&
                `${x.Serie}-${x.Name}`
                  .toUpperCase()
                  .search(term.toUpperCase()) > -1
            )
              .map((x) => `${x.Serie}-${x.Name}`)
              .slice(0, 10)
      )
    );
  };

  updateSerieModelWithFormValues() {
    let temporalModel = {
      Active: Number(this.formSerieSAP.get("Active").value),
      CompanyId: 0,
      DocType: this.formSerieSAP.get("DocType").value,
      FESerieId: this.serieInEdition ? (this.serieInEdition.FESerie)? this.serieInEdition.FESerieId : 0 : 0,
      FESerie: null,
      GenerationType: this.formSerieSAP.get("GenerationType").value,
      Id: this.serieInEdition ? this.serieInEdition.Id : 0,
      Name: this.formSerieSAP.get("Name").value,
      NextNumber: this.formSerieSAP.get("NextNumber").value,
      SerieSAP: Number(this.formSerieSAP.get("SerieSAP").value.split("-")[0]),
      Share: this.formSerieSAP.get("Share").value === 1,
      Type: this.formSerieSAP.get("Type").value,
    };

    if (this.useFESerie) {
      temporalModel.FESerie = {
        Active: Boolean(this.formSerieFE.get("Active").value),
        DocType: "01", // por el momento se mantiene el DocType empotrado porque solo se usa para facturas
        Id: this.serieInEdition ? (this.serieInEdition.FESerie)? this.serieInEdition.FESerieId : 0 : 0,
        Name: this.formSerieFE.get("Name").value,
        Sucursal: this.formSerieFE.get("Sucursal").value,
        Terminal: this.formSerieFE.get("Terminal").value,
        NextNumber: this.formSerieFE.get("NextNumber").value,
      };
    } else {
      temporalModel.FESerieId = null;
    }

    this.serieInEdition = temporalModel;
    temporalModel = null;
  }

  onClickSaveSerie() {
    this.blockUI.start();
    this.updateSerieModelWithFormValues();

    this.seriesService.handlePostOrPutSerie(this.serieInEdition).subscribe(
      (response) => {
        this.blockUI.stop();

        if (response.result) {
          this.initComponent();
          this.alertService.successInfoAlert("Proceso finalizado exitosamente");
        } else {
          this.alertService.errorAlert(response.errorInfo.Message);
        }
      },
      (err) => {
        this.blockUI.stop();
        this.alertService.errorAlert(err);
      }
    );
  }

  getSerieDocumentType(documentType: number) {
    switch (documentType) {
      case NumberingSeries.FacturaFE:
        return "Factura - FE";
      case NumberingSeries.FacturaTE:
        return "Factura - TE";
      case NumberingSeries.FacturaReservaFE:
        return "Factura Reserva - FE";
      case NumberingSeries.FacturaReservaTE:
        return "Factura Reserva - TE";
      case NumberingSeries.Oferta:
        return "Oferta de Ventas";
      case NumberingSeries.Orden:
        return "Orden de Ventas";
      case NumberingSeries.PagoRecibido:
        return "Pago Recibido";
      case NumberingSeries.SocioNegocios:
        return "Socio de negocios";
    }
  }

  getSerieObjectTypeInSAP(documentType: number) {
    switch (documentType) {
      case NumberingSeries.Oferta:
        return "23";
      case NumberingSeries.Orden:
        return "17";
      case NumberingSeries.PagoRecibido:
        return "24";
      case NumberingSeries.SocioNegocios:
        return "2";
      default:
        return documentType.toString();
    }
  }

  onClickAssignSerie(assignSerieModal) {
    this.formSerieAssign.get("user").setValue(this.userIdToSearchSeries);
    this.assignationModal = this.modalService.open(assignSerieModal, {
      backdrop: "static",
      centered: true,
      size: "lg",
    });
  }

  onClickDismissModal() {
    this.assignationModal.close();
  }

  onClickSaveSerieAssignation() {
    let serieId = Number(this.formSerieAssign.get("serie").value);
    let userMappId = Number(this.formSerieAssign.get("user").value);
    let serieModel = this.series.find((x) => x.Id === serieId);
    if (!serieModel) return;

    this.resetFormValues(false, false, true);
    this.blockUI.start();
    this.onClickDismissModal();

    this.seriesService
      .assignSerie(userMappId, serieModel.Id, serieModel.Share)
      .subscribe(
        (response) => {
          this.blockUI.stop();
          if (response.result) {
            this.alertService.successInfoAlert(
              "Proceso finalizado exitosamente"
            );
            this.initComponent();
          } else this.alertService.errorAlert(response.errorInfo.Message);
        },
        (err) => {
          this.blockUI.stop();
          this.alertService.errorAlert(err);
        }
      );
  }

  async onClickDeleteAssign(serieId: number, index: number) {
    let response = await this.alertService.confirmationAlert(
      "Confirmación",
      "¿Desea eliminar la asignación de esta serie?",
      "Eliminar"
    );
    if (response) {
      this.blockUI.start();

      this.seriesService
        .deleteAssignation(serieId, this.userIdToSearchSeries)
        .subscribe(
          (response) => {
            this.blockUI.stop();
            if (response.result) {
              this.seriesByUser.splice(index, 1);
              this.alertService.successInfoAlert(
                "Proceso finalizado exitosamente"
              );
            } else this.alertService.errorAlert(response.errorInfo.Message);
          },
          (err) => {
            this.blockUI.stop();
            this.alertService.errorAlert(err);
          }
        );
    }
  }

  get formSAPControls() {
    return this.formSerieSAP.controls;
  }
  get formFEControls() {
    return this.formSerieFE.controls;
  }
  get formAssignControls() {
    return this.formSerieAssign.controls;
  }

  getSAPSerieNameBySerieNumber(serieNumber: number) {
    if (!this.SAPSeries || this.SAPSeries.length === 0) return "";

    return this.SAPSeries.find((x) => x.Serie === serieNumber).Name;
  }

  onSerieSelected($event: any) {
    $event.preventDefault();
    this.formSerieSAP.get("SerieSAP").reset($event.item);
  }

  onGenerationTypeChange() {
    const generationType = Number(
      this.formSerieSAP.get("GenerationType").value
    );
    this.formSerieSAP.get("NextNumber").clearValidators();

    if (generationType === 2)
      this.formSerieSAP.get("NextNumber").setValidators(Validators.required);

    this.formSerieSAP.get("NextNumber").updateValueAndValidity();
  }

  PageChange(): void {
    this.pagedSeries = this.series.slice(this.rowsByPage * (this.currentPage - 1), this.rowsByPage * this.currentPage);
  }
}
