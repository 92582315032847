import { Component, OnInit } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

// SERVICES
import { DiscountHierarchiesService, AlertService } from 'src/app/services';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { DiscountHierarchy } from 'src/app/models';
import { Discounts } from 'src/app/enums';

@Component({
  selector: 'app-herachies',
  templateUrl: './herachies.component.html',
  styleUrls: ['./herachies.component.css']
})
export class HerachiesComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  discountHierarchy: DiscountHierarchy[] = [];

  constructor(private discountService: DiscountHierarchiesService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.getDiscountHierarchy();
  }

  getDiscountHierarchy() {
    this.blockUI.start();
    this.discountService.GetDiscountHierarchy().subscribe(response => {
      this.blockUI.stop();
      if (response.result) {
        this.discountHierarchy = response.DiscountHierarchies;
      } else {
        this.discountHierarchy = [];
        this.alertService.errorAlert(response.errorInfo.Message);
        console.log(response.errorInfo.Message);
      }
    }, err => {
      console.log(err);
      this.alertService.errorAlert(err);
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    this.moveItemInArray(this.discountHierarchy, event.previousIndex, event.currentIndex);
  }

  moveItemInArray(arr: any[], fromIndex, toIndex) {
    let element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);

    this.setHierarchy();
  }

  setHierarchy() {
    if (!this.discountHierarchy) return;

    this.discountHierarchy.forEach((x, index) => {
      x.Hierarchy = index + 1;
    });
  }

  getDiscountName(type: number) {
    switch (type) {
      case Discounts.BP:
        return 'Socio de negocios';
      case Discounts.BPGroupXItem:
        return 'Grupo de socio de negocios por Item';
      case Discounts.BPGroupXItemGroup:
        return 'Grupo de socio de negocios por Grupo de items';
      case Discounts.BPXItem:
        return 'Socio de negocios por Item';
      case Discounts.BPXItemFamily:
        return 'Socio de negocios por Familia de items';
      case Discounts.BPXItemGroup:
        return 'Socio de negocios por Grupo de items';
      case Discounts.ItemGroup:
        return 'Grupo de items';
      default:
        return '';
    }
  }

  putDiscountHierarchy() {
    this.blockUI.start();
    this.discountService.PutDiscountHierarchy(this.discountHierarchy).subscribe(response => {
      this.blockUI.stop();
      if (response.result) {
        this.alertService.successInfoAlert('Proceso finalizado exitosamente');
      } else {
        this.alertService.errorAlert(response.errorInfo.Message);
        console.log(response.errorInfo.Message);
      }
    }, err => {
      this.blockUI.stop();
      this.alertService.errorAlert(err);
      console.log(err);
    });
  }

}
