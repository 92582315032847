import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "./services/auth.guard";

import { HomeComponent } from "./components/home/home.component";
import { CreateRouteComponent } from "./components/route/create-route/create-route.component";
import { LoginComponent } from "./components/login/login.component";
import { UsersViewComponent } from "./components/users/users-view/users-view.component";
import { UsersConfComponent } from "./components/users/users-conf/users-conf.component";
import { UsersAssingsComponent } from "./components/users/users-assings/users-assings.component";
import { UsersAssingsConfigComponent } from "./components/users/users-assings-config/users-assings-config.component";
import { FrequencyConfComponent } from "./components/frequency/frequency-conf/frequency-conf.component";
import { ListRouteComponent } from "./components/route/list-route/list-route.component";
import { FrequencyViewComponent } from "./components/frequency/frequency-view/frequency-view.component";
import { CompanyComponent } from "./components/company/company.component";
import { PermsByRolComponent } from "./components/permissions/perms-by-rol/perms-by-rol.component";
import { RolByUserComponent } from "./components/rol/rol-by-user/rol-by-user.component";
import { BPViewComponent } from "./components/bpview/bpview.component";
import { RecoverEmailComponent } from "./components/recover-email/recover-email.component";
import { ListPromotionsComponent } from "./components/promotions/list-promotions/list-promotions.component";
import { FocusItemsComponent } from "./components/item/focus-items/focus-items.component";

// GUARDS
import {
  AuthGuardCustomerService,
  AuthGuardFrequencyService,
  AuthGuardPermByRolService,
  AuthGuardProdFocoService,
  AuthGuardPromotionService,
  AuthGuardRolByUserService,
  AuthGuardRouteAssignService,
  AuthGuardRouteService,
  AuthGuardUserService,
  AuthGuardUserAssingService,
  AuthGuardRouteConfigService,
  AuthGuardUserConfigService,
  AuthGuardUserAssignConfigService,
  AuthGuardFrequencyConfigService,
  AuthGuardCompanyConfigService,
  AuthGuardEventViewerGuard
} from "./guards/index";
import { RolsComponent } from "./components/rol/rols/rols.component";
import { UdfsViewComponent } from "./components/udf/udfs.component";
import { GeoRolsComponent } from "./components/geo-rols/geo-rols.component";
import { NumberingSeriesComponent } from "./components/numbering-series/numbering-series.component";
import { ReportsComponent } from "./components/rpt-manager/reports/reports.component";
import { PrintReportComponent } from "./components/rpt-manager/print-report/print-report.component";
import { StashedDocumentsComponent } from './components/stashed-documents/stashed-documents.component';
import { AuthGuardStashedDocumentsGuard } from './guards/auth-guard-stashed-documents.guard';
import { EventViewerComponent } from './components/event-viewer/event-viewer.component';

const routes: Routes = [
  { path: "", component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: "listPromotions",
    component: ListPromotionsComponent,
    canActivate: [AuthGuardPromotionService],
  },
  {
    path: "createRoute/:Id",
    component: CreateRouteComponent,
    canActivate: [AuthGuardRouteConfigService],
  },
  {
    path: "listRoute",
    component: ListRouteComponent,
    canActivate: [AuthGuardRouteService],
  },
  { path: "login", component: LoginComponent },
  {
    path: "UserAdd",
    component: UsersViewComponent,
    canActivate: [AuthGuardUserService],
  },
  {
    path: "userConf/:userId",
    component: UsersConfComponent,
    canActivate: [AuthGuardUserConfigService],
  },
  {
    path: "userAssings",
    component: UsersAssingsComponent,
    canActivate: [AuthGuardUserAssingService],
  },
  {
    path: "userAssingsconf/:userId",
    component: UsersAssingsConfigComponent,
    canActivate: [AuthGuardUserAssignConfigService],
  },
  {
    path: "frequency",
    component: FrequencyViewComponent,
    canActivate: [AuthGuardFrequencyService],
  },
  {
    path: "frequencyConf/:Id",
    component: FrequencyConfComponent,
    canActivate: [AuthGuardFrequencyConfigService],
  },
  { path: "UDFs", component: UdfsViewComponent },
  {
    path: "companies",
    component: CompanyComponent,
    canActivate: [AuthGuardCompanyConfigService],
  },
  {
    path: "permsByRol",
    component: PermsByRolComponent,
    canActivate: [AuthGuardPermByRolService],
  },
  {
    path: "rolByUser",
    component: RolByUserComponent,
    canActivate: [AuthGuardRolByUserService],
  },
  { path: "rols", component: RolsComponent },
  { path: "geoRols", component: GeoRolsComponent },
  {
    path: "BP",
    component: BPViewComponent,
    canActivate: [AuthGuardCustomerService],
  },
  { path: "recoveremail/:email", component: RecoverEmailComponent },
  {
    path: "focusItems",
    component: FocusItemsComponent,
    canActivate: [AuthGuardProdFocoService],
  },
  {
    path: "stashed-documents",
    component: StashedDocumentsComponent,
    canActivate: [AuthGuardStashedDocumentsGuard]
  },
  {
    path: "event-viewer",
    component: EventViewerComponent,
    canActivate: [AuthGuardEventViewerGuard]
  },
  { path: "series", component: NumberingSeriesComponent },
  { path: "reports", component: ReportsComponent },
  { path: "print-report/:reportId", component: PrintReportComponent },
  { path: "home", pathMatch: "full", redirectTo: "" },
  { path: "**", pathMatch: "full", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
