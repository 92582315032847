export class AppConstants {
  // PRUEBAS DEBUG
  // public static get apiUrl(): string { return "http://localhost:60048/"; }
  // TEST
  // public static get apiUrl(): string { return 'https://hseraapitest.clavisco.com/'; }
  // API
   public static get apiUrl(): string { return 'https://hseraapidev.clavisco.com/'; }
  public static GetError(_error: any): string {

    let joined_error;

    if(_error.error && _error.error.errorInfo){
      joined_error = _error.error.errorInfo.Message;

    } else if(_error.error && _error.error.error_description){
      joined_error = _error.error.error_description;

    } else if(_error.error && _error.error.Message){
      joined_error = _error.error.Message;

    } else if (_error.message) {
      joined_error = _error.message;

    } else if (_error.errorInfo && _error.errorInfo.Message) {
      joined_error = _error.errorInfo.Message;

    } else if(_error.error){
      joined_error = _error.error;

    } else{
      joined_error = _error;
    }

    if (typeof joined_error === 'string') return joined_error;

    return JSON.stringify(_error);
  }

  public static KeyReportManager: number;
}

export const DiscountPromoType = [
  {
    Id: "1",
    Name: "Descuento",
  },
  {
    Id: "2",
    Name: "Precio",
  },
];

export const SequencePromoType = [
  {
    Id: "1",
    Name: "Siguiente",
  },
  {
    Id: "2",
    Name: "Todos los Siguientes",
  },
  {
    Id: "3",
    Name: "Todos",
  },
];

export const Week = [
  {
    Value: 1,
    Name: "Domingo",
  },
  {
    Value: 2,
    Name: "Lunes",
  },
  {
    Value: 3,
    Name: "Martes",
  },
  {
    Value: 4,
    Name: "Miércoles",
  },
  {
    Value: 5,
    Name: "Jueves",
  },
  {
    Value: 6,
    Name: "Viernes",
  },
  {
    Value: 7,
    Name: "Sábado",
  },
];

export const Frequencies = [
  {
    Name: "Todas las semanas",
    Value: "0",
  },
  {
    Name: "Primera semana",
    Value: "1",
  },
  {
    Name: "Segunda semana",
    Value: "2"
  },
  {
    Name: "Tercera semana",
    Value: "3"
  },
  {
    Name: "Última semana",
    Value: "4",
  },
  {
    Name: "Primera y segunda semana",
    Value: "1.2",
  },
  {
    Name: "Primera y tercera semana",
    Value: "1.3",
  },
  {
    Name: "Primera y última semana",
    Value: "1.4",
  }
];

export const DocumentSubtypes = [
  {
    Parents: ["13", "3"],
    Value: 1,
    Name: "Factúra Electrónica",
  },
  {
    Parents: ["13", "3"],
    Value: 2,
    Name: "Tiquete Electrónico",
  },
  {
    Parents: ["17", "23", "24", "2"],
    Value: -1,
    Name: "No posee subtipos",
  },
];

export const TransactionStatus = [
  {
    Value: -1,
    Name: "Todos",
    Icon: '',
    Color: ''
  },
  {
    Value: 0,
    Name: "En cola",
    Icon: 'circle',
    Color: 'primary'
  },
  {
    Value: 1,
    Name: "Procesando",
    Icon: 'ellipsis-h',
    Color: 'info'
  },
  {
    Value: 2,
    Name: "Con errores",
    Icon: 'times-circle',
    Color: 'danger'
  },
  {
    Value: 3,
    Name: "Completado",
    Icon: 'check-circle',
    Color: 'success'
  }
];

export const DocumentTypes = [
  {
    Value: -1,
    Name: "Todos",
  },
  {
    Value: 8,
    Name: "Oferta de venta",
  },
  {
    Value: 4,
    Name: "Orden de venta",
  },
  {
    Value: 1,
    Name: "Factura deudor",
  },
  {
    Value: 5,
    Name: "Factura deudor + pago",
  },
  {
    Value: 3,
    Name: "Factura de reserva",
  },
  {
    Value: 6,
    Name: "Pago recibido",
  },
  {
    Value: 2,
    Name: "Pago a cuenta",
  },
  {
    Value: 7,
    Name: "Pago a factura",
  }
];

export const LogMobileTypes = [
  {
    Value: -1,
    Name: "Todos",
  },
  {
    Value: 0,
    Name: "Información",
  },
  {
    Value: 1,
    Name: "Éxito",
  },
  {
    Value: 2,
    Name: "Advertencia",
  },
  {
    Value: 3,
    Name: "Error",
  },
];

export const Menu = [
  {
    Name: "Inicio",
    Route: "home",
    Icon: "fa fa-home",
    Children: [],
    Selected: true,
    Type: 1,
    RequiredPerm: "",
    Visible: true,
  },
  {
    Name: "Rutas",
    Route: "listRoute",
    Icon: "fa fa-road",
    Children: [
      {
        Name: "Nueva ruta",
        Route: "createRoute/0",
        Icon: "",
        Children: [],
        Selected: false,
        Type: 2,
        Visible: false,
        RequiredPerm: "G_CreateRoute",
      }
    ],
    Selected: false,
    Type: 1,
    Visible: false,
    RequiredPerm: "S_VerRuta",
    Collapsed: true
  },
  {
    Name: "Reportes",
    Route: "",
    Icon: "fa fa-bar-chart",
    Children: [],
    Selected: false,
    Type: 1,
    Visible: false,
    RequiredPerm: "B_Reports",
  },
  {
    Name: "Documentos",
    Route: "stashed-documents",
    Icon: "fa fa-th-list",
    Children: [],
    Selected: false,
    Type: 1,
    Visible: false,
    RequiredPerm: "S_ViewStashedDocuments",
  },
  {
    Name: "Configuraciones",
    Route: "",
    Icon: "fa fa-cogs",
    Selected: false,
    Type: 1,
    Visible: true,
    RequiredPerm: "",
    Children: [
      {
        Name: "Compañías",
        Route: "companies",
        Icon: "",
        Children: [],
        Selected: false,
        Type: 2,
        Visible: false,
        RequiredPerm: "S_MantCompany",
      },
      {
        Name: "Administrar reportes",
        Route: "reports",
        Icon: "fa fa-bar-chart",
        Children: [],
        Selected: false,
        Type: 2,
        Visible: false,
        RequiredPerm: "B_Reports",
      },
      {
        Name: "Series de numeración",
        Route: "series",
        Icon: "",
        Children: [],
        Selected: false,
        Type: 2,
        Visible: false,
        RequiredPerm: "S_MantSeries",
      },
      {
        Name: "UDFs",
        Route: "UDFs",
        Icon: "",
        Children: [],
        Selected: false,
        Type: 2,
        Visible: false,
        RequiredPerm: "S_MantUDFs",
      },
      {
        Name: "Promociones",
        Route: "listPromotions",
        Icon: "",
        Children: [],
        Selected: false,
        Type: 2,
        Visible: false,
        RequiredPerm: "S_MantPromo",
      },
      {
        Name: "Productos foco",
        Route: "focusItems",
        Icon: "",
        Children: [],
        Selected: false,
        Type: 2,
        Visible: false,
        RequiredPerm: "S_MantProdFoco",
      },
      {
        Name: "Activación clientes",
        Route: "BP",
        Icon: "",
        Children: [],
        Selected: false,
        Type: 2,
        Visible: false,
        RequiredPerm: "S_MantCliente",
      },
      {
        Name: "Usuarios",
        Route: "UserAdd",
        Icon: "",
        Children: [],
        Selected: false,
        Type: 2,
        Visible: false,
        RequiredPerm: "S_MantUsers",
      },
      {
        Name: "Asignación usuarios",
        Route: "userAssings",
        Icon: "",
        Children: [],
        Selected: false,
        Type: 2,
        Visible: false,
        RequiredPerm: "S_AssignUsers",
      },
      {
        Name: "Roles",
        Route: "rols",
        Icon: "",
        Children: [],
        Selected: false,
        Type: 2,
        Visible: false,
        RequiredPerm: "S_CrearRol",
      },
      {
        Name: "Roles geo",
        Route: "geoRols",
        Icon: "",
        Children: [],
        Selected: false,
        Type: 2,
        Visible: false,
        RequiredPerm: "S_RolGeo",
      },
      {
        Name: "Frecuencias",
        Route: "frequency",
        Icon: "",
        Children: [],
        Selected: false,
        Type: 2,
        Visible: false,
        RequiredPerm: "S_MantFreq",
      },
    ],
  },
  {
    Name: "Visor de eventos",
    Route: "event-viewer",
    Icon: "fa fa-stack-exchange",
    Children: [],
    Selected: false,
    Type: 1,
    Visible: false,
    RequiredPerm: "S_ViewEventViewer",
  },
  {
    Name: "Salir",
    Route: "",
    Icon: "fa fa-sign-out",
    Children: [],
    Selected: false,
    Type: 1,
    Visible: true,
    RequiredPerm: "",
  },
];
