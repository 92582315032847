import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class Globals {

  viewParamListSubMenu: any[] = []; // llena la lista con los permisos parametrizados del submenu principal
  viewParamListMenu: any[] = []; // llena la lista con los permisos parametrizados del menu principal
  generalWebPermList: any[] = []; // llena la lista con los permisos parametrizados de la aplicacion
  perms: any[];
  sidebarToggler: Subject<boolean>;

  constructor() {
    this.sidebarToggler = new Subject();
  }

  initPerms() {
    this.viewParamListMenu = [];
    this.viewParamListSubMenu = [];
    this.generalWebPermList = [];
  }

}
