import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// MODELOS
import { AppConstants, FocusItemUIResponse, ItemMobileResponse, ItemsMobileModel, FocusItemUI, BaseResponse } from './../models/index';
import { StorageService } from './storage.service';

// RUTAS

// COMPONENTES

// SERVICIOS

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  // mmetodo para obtener los item foco
  getFocusItems(GroupCode: number, U_SubTipo: string) {

    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.get<FocusItemUIResponse>(`${AppConstants.apiUrl}api/Items/GetFocusItems?GroupCode=${GroupCode}&U_SubTipo=${U_SubTipo}`,
      { headers }
    );

  }

  // mmetodo para obtener los items de SAP
  getItems() {

    let currentUser = JSON.parse(this.storage.getCurrentSession());

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${currentUser.access_token}`
    });

    return this.http.get<ItemMobileResponse>(`${AppConstants.apiUrl}api/Items/ItemMobile?userMappId=${currentUser.userMappId}`,
      { headers }
    );

  }

  // Metodo para crear un producto foco
  createItemFocus(itemFocus: FocusItemUI) {

    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    return this.http.post<BaseResponse>(`${AppConstants.apiUrl}api/Items/CreateItemFocus`,
      itemFocus,
      { headers }
    );
  }

  // Metodo para eliminar un producto foco
  deleteItemFocus(itemFocus: FocusItemUI) {

    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });

    return this.http.post<BaseResponse>(`${AppConstants.apiUrl}api/Items/DeleteItemFocus`,
      itemFocus,
      { headers }
    );
  }

}
