import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '.';
import { ApiResponse, AppConstants, IGlobalSearch, IPagedLogsMobile } from '../models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LogsMobileService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
    ) { }

  
  GetPagedLogsMobile(globalSearch: IGlobalSearch, offset: number, rows: number): Observable<ApiResponse<IPagedLogsMobile>> {
    const TOKEN = JSON.parse(this.storage.getCurrentSession());
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${TOKEN.access_token}`
    });
    
    return this.http.post<ApiResponse<IPagedLogsMobile>>(
      `${AppConstants.apiUrl}api/LogsMobile/GetPagedLogsMobile?offset=${offset}&rows=${rows}`, 
      globalSearch, 
      { headers });
  }
}
