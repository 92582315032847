import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BlockUI, BlockUIService, NgBlockUI } from 'ng-block-ui';

// MODELOS
import { HardCodedReport, MailDataModel, SapConModel, MailDataModelResponse, CompanyResponse, CompanyInfoModel, CompanyListResponse, BaseResponse } from '../../models/index';

// RUTAS

// COMPONENTES

// SERVICIOS
import { CompanyService, AlertService, StorageService } from '../../services/index';
import { Globals } from 'src/app/globals';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ReportType } from 'src/app/enums/enums';
import { ISetting, ILogSetting, ISchedulingSetting, SettingKeyValue } from '../../models/i-setting';
import { Key } from 'selenium-webdriver';
import { KeyValuePipe } from '@angular/common';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-company-conf',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  // varbox
  settings: ISetting[];
  LogSettingsForm: FormGroup;
  LogSettingsModal: NgbModalRef;

  SchedulingSettingsForm: FormGroup;
  SchedulingSettingsModal: NgbModalRef;

  ValidateInvSettingForm: FormGroup;
  ValidateInvSettingModal: NgbModalRef;

  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('ngbTabset') tabset;
  companyId: number;
  mailDataId: number;
  companyMailForm: FormGroup;
  title: string;
  create: boolean;
  sapConnectionList: SapConModel[] = [];
  selectedFile: File = null;
  imgFileName: string;
  logoPath: string;
  G_CreateCompany: boolean;
  G_EditCompany: boolean;
  companyList: CompanyInfoModel[] = [];
  useServiceLayer: FormControl;

  //Vars Reports
  HardCodedReports: HardCodedReport[];
  ModalHardCodedReport: NgbModalRef;
  HardCodedReportFiles: {[id: string] : any} = {};
  closeResult: string;


  constructor(private fb: FormBuilder,
    private router: Router,
    private globals: Globals,
    private companyService: CompanyService,
    private storageService: StorageService,
    private alertService: AlertService,
    private modalService: NgbModal) {
  }

  ngOnInit() {
    this.initializePage();
  }

  initializePage() {
    this.G_CreateCompany = this.globals.generalWebPermList.some(x => x.Name === 'G_CreateCompany');
    this.G_EditCompany = this.globals.generalWebPermList.some(x => x.Name === 'G_EditCompany');
    this.mailDataId = 0;
    this.chargeCompanyList();
    this.setNewFormData();
    this.setData();
    this.chargeSapConList();
    this.InitHardCodedReports();
    this.ResetLogSettingsForm();
    this.ResetSchedulingSettingsForm();
    this.LoadSettings();

    this.ValidateInvSettingForm = this.fb.group({
      OINV: [false],
      ORDR: [false],
      OQUT: [false]
    });
  }

  get fCM() { return this.companyMailForm.controls; }

  chargeCompanyList() {
    this.blockUI.start();
    this.companyService.GetCompanys().subscribe((data: CompanyListResponse) => {
      this.blockUI.stop();
      if (data.result) {
        this.companyList = data.CompanyList;
      } else {
        this.alertService.warningAlert(`${data.errorInfo.Message}`);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorAlert(`${error}`);
    });
  }

  setNewFormData() {
    this.companyMailForm = this.fb.group({
      DBName: ['', [Validators.required, Validators.minLength(1)]],
      DBCode: ['', [Validators.required, Validators.minLength(1)]],
      SAPConnectionId: [''],
      CheckInTime: ['', Validators.required],
      Warehouses: ['', Validators.required],
      Active: [false],
      Mails: [false],
      subject: [''],
      from: [''],
      user: [''],
      pass: [''],
      port: [''],
      Host: [''],
      SSL: [false],
      BillingRange: [''],
      useBillingRange: [false],
      HasFreight: [false],
      HasMixedMode: [false],
      HasHeaderDiscount: [false]
    });
  }

  setData() {
    if (!this.companyId) {
      this.create = true;
      this.title = 'Crear';
    } else {
      this.create = false;
      this.title = 'Editar';
      this.GetCompany(this.companyId);
    }
  }

  GetCompany(Id: number) {
    this.blockUI.start();
    this.companyService.GetCompany(Id)
      .subscribe((data: CompanyResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.ChargeCompanyInfo(data.Company);
        } else {
          this.alertService.warningInfoAlert(`${data.errorInfo.Message}`);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.errorInfoAlert(`${error}`);
      });
  }

  ChargeCompanyInfo(company: CompanyInfoModel) {

    if (company.LogoPath && company.LogoPath !== '') {
      this.logoPath = company.LogoPath;
      const tempLogo = company.LogoPath.substring(company.LogoPath.lastIndexOf('\\'), company.LogoPath.length);
      this.imgFileName = tempLogo.substring(1, tempLogo.length);
    }
    this.companyMailForm.patchValue({
      DBName: company.DBName,
      DBCode: company.DBCode,
      SAPConnectionId: company.SAPConnectionId,
      Active: company.Active,
      CheckInTime: company.CheckInTime,
      Warehouses: company.Warehouses,
      Mails: company.SendMails,
      BillingRange: company.BillingRange,
      useBillingRange: company.BillingRange ? true : false,
      HasFreight: company.HasFreight,
      HasMixedMode: !company.HasMixedMode,
      HasHeaderDiscount: company.HasHeaderDiscount
    });
    this.seriesFormControlValidators(this.fCM.useBillingRange, this.fCM.BillingRange);
    this.GetMailInfo(company.MailDataId);
  }

  GetMailInfo(Id: number) {
    this.blockUI.start();
    this.companyService.GetMailInfo(Id)
      .subscribe((data: MailDataModelResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.ChargeMailInfo(data.mailData);
        } else {
          this.alertService.warningAlert(`${data.errorInfo.Message}`);
        }
      }, error => {
        this.blockUI.stop();
        this.alertService.errorAlert(`${error}`);
      });
  }

  ChargeMailInfo(mail: MailDataModel) {
    this.companyMailForm.patchValue({
      subject: mail.subject,
      from: mail.from,
      user: mail.user,
      pass: mail.pass,
      port: mail.port,
      Host: mail.Host,
      SSL: mail.SSL
    });
  }

  chargeSapConList() {
    this.blockUI.start();
    this.companyService.GetSapCon().subscribe((data: any) => {
      this.blockUI.stop();
      if (data.result) {
        this.sapConnectionList = data.SapConList;
        this.companyMailForm.patchValue({ SAPConnectionId: this.sapConnectionList[0].Id });
      } else {
        this.alertService.errorAlert(`${data.errorInfo.Message}`);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorAlert(`${error}`);
    });
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.companyMailForm.invalid) {
      this.alertService.warningInfoAlert('El formulario de compañía contiene errores');
      return;
    }

    this.blockUI.start(); // Start blocking
    this.SaveServiceLayerSettings();
    this.companyService.CreateCompanyAndMail(this.companyMailForm, this.companyId,
    this.mailDataId, this.selectedFile, this.alertService, !this.create ? this.logoPath : undefined);
    this.tabset.activeId = 'tabList';
    this.setNewFormData();
  }

  // funcion para seleccionar un archivo a guardar
  onFileSelected(event) {
    let file = <File>event.target.files[0];
    let ext = this.GetFileExtension(file.name);

    if (ext === 'png' || ext === 'jpg' || ext === 'jpeg' || ext === 'svg') {
      this.selectedFile = file;
      this.imgFileName = this.selectedFile.name;
    } else {
      this.alertService.warningAlert('Formato de archivo no soportado');
    }
  }

  GetFileExtension(fileName: string) {
    return fileName.substring(fileName.lastIndexOf('.') + 1);
  }

  // valida si el formgroup toChange debe ser requerido o no dependiendo del valor de toValidate
  seriesFormControlValidators(toValidate: any, toChange: any) {
    if (toValidate.value) {
      toChange.setValidators(Validators.required);
    } else {
      toChange.clearValidators();
    }
    toChange.updateValueAndValidity();
  }

  // si el FormControl con el número de serie está vacío
  // desactivamos el check que lo marca como requerido
  seriesInputChange(serieNumber: any, serieRequired: any) {
    if (serieNumber.errors) {
      serieRequired.setValue(false);
      this.seriesFormControlValidators(serieRequired, serieNumber);
    }
  }

  deleteIcon() {
    this.logoPath = undefined;
    this.selectedFile = undefined;
    this.imgFileName = '';
  }

  onTabChange(event) {
    if (event.nextId === 'tabList') {
      this.companyId = 0;
      this.create = true;
      this.title = 'Crear';
      this.setNewFormData();
    }
  }

  onClickEditCompany(Id: number) {
    this.companyId = Id;
    this.tabset.activeId = 'tabCrud';
    this.create = false;
    this.title = 'Guardar';
    this.GetCompany(this.companyId);
  }

  // Sección de Hard Coded Reports

  InitHardCodedReports(): void{
    this.HardCodedReports = []
    this.HardCodedReportFiles = {}

    this.blockUI.start(`Iniciando reportes, por favor espere...`);
    this.companyService.GetHardCodedReportTypes().subscribe(next => {
      this.blockUI.stop();
      if (next.result) {
        this.HardCodedReports = next.Data;
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.successAlert(`Error: ${error}`);
    });
  }

  FileBrowseHandler(Event, ReportType: number): void {
    if (Event.srcElement.files) {
      let FileExtention: string = Event.srcElement.files[0].name.split('.').pop();
      if (FileExtention.toUpperCase() === 'RPT') {
        this.HardCodedReports.forEach(HardCodedReport => {
          if (HardCodedReport.ReportType == ReportType) {
            // this.file = Event.srcElement.files;
            HardCodedReport.FileName = Event.srcElement.files[0].name;
            this.HardCodedReportFiles[ReportType] = Event.srcElement.files[0];
          }
        });
      } else {
        this.alertService.errorAlert(`El tipo de archivo debe ser rpt`);
      }
    }
  }

  DownLoadHardCodedReport(HardCodedReport: HardCodedReport): void {
    this.companyService.DownloadHardCodedReport(HardCodedReport.ReportType).subscribe(resp => {
      if (resp.result) {
        this.DowloadFile(resp.Data, HardCodedReport.FileName.split('.')[0], 'application/octet-stream', 'rpt');
      } else {
        this.alertService.errorAlert(`Error al descargar archivo.${resp.errorInfo.Message}`);
      }
    }, error => {
      this.alertService.errorAlert(`Error: ${error}`);
    });
  }

  private DowloadFile(
    base64File: string,
    fileName: string,
    blobType: string,
    fileExtension: string
  ): void {
    let report = new Blob([this.StringToArrayBuffer(atob(base64File))], {
      type: blobType,
    });

    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(report);
    link.download = `${fileName}.${fileExtension}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private StringToArrayBuffer(toConvert: string): ArrayBuffer {
    let buf = new ArrayBuffer(toConvert.length);
    let view = new Uint8Array(buf);
    for (let i = 0; i != toConvert.length; ++i)
      view[i] = toConvert.charCodeAt(i) & 0xff;
    return buf;
  }

  OpenAndLoadHardCodedReports(content): void {
    this.blockUI.start(`Cargando reportes, por favor espere`);
    this.companyService.GetHardCodedReports().subscribe(next => {
      this.blockUI.stop();
      if (next.result) {
        this.HardCodedReports.forEach(LocalHardCodedReport => {
          next.Data.forEach(HardCodedReport => {
            if(LocalHardCodedReport.ReportType == HardCodedReport.ReportType){
              LocalHardCodedReport.FileName = HardCodedReport.FileName;
            }
          })
        })
      }
      this.ModalHardCodedReport = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'Modal-sm' });
    }, error => {
      this.blockUI.stop();
      this.alertService.errorAlert(`Error: ${error}`);
    });
  }

  SaveHardCodedReports(): void {
    let frmdata = new FormData();
    frmdata.append('hardCodedReports', JSON.stringify(this.HardCodedReports));

    this.HardCodedReports.forEach(Report => {
      if(this.HardCodedReportFiles[Report.ReportType]){
        frmdata.append('file_'+Report.ReportType, this.HardCodedReportFiles[Report.ReportType]);
      }
    })

    this.companyService.UpdateHardCodedReports(frmdata, this.alertService);
  }

  CloseHardCodedReports(): void {
    this.ModalHardCodedReport.close();
  }

  // Fin Hard Coded Reports

  // Inicio configuracion de eventos

  ResetLogSettingsForm(): void {
    this.LogSettingsForm = this.fb.group({
      SyncInfo: [false, [Validators.required]],
      SyncSuccess: [false, [Validators.required]],
      SyncWarning: [false, [Validators.required]],
      SyncError: [false, [Validators.required]],
    });
  }

  OpenAndLoadLogSettings(content): void {
    let setting: ISetting = this.settings.find(x => x.Code == 'LogMobileSettings');
    if(setting){
      this.LogSettingsForm.patchValue(JSON.parse(setting.Json));
    }
    this.LogSettingsModal = this.modalService.open(
      content,
      { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'Modal-sm' }
    );
  }

  SaveLogSettings(): void {
    this.blockUI.start("Guardando la configuración de eventos...")
    let setting: ISetting = this.settings.find(x => x.Code == 'LogMobileSettings');
    if(setting){
      setting.Json = JSON.stringify(this.LogSettingsForm.value as ILogSetting);
    } else {
      setting = {
        Id: -1,
        Code: 'LogMobileSettings',
        View: 'Logs',
        Json: JSON.stringify(this.LogSettingsForm.value as ILogSetting)
      }
    }

    this.companyService.StoreSetting(setting).subscribe(req => {
      this.blockUI.stop();
      if(req.result){
        this.CloseLogSettingsModal();
        this.alertService.successAlert('Configuración de eventos guardada exitosamente!');
      } else {
        this.alertService.errorAlert(`Error: ${req.errorInfo.Message}`);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorAlert(`Error: ${error}`);
    });
  }

  CloseLogSettingsModal(): void {
    this.LogSettingsModal.close();
  }

  // Fin configuracion de eventos

  LoadSettings(){
    this.blockUI.start("Cargando la configuración de eventos...")
    this.companyService.GetSettings().subscribe(req => {
      this.blockUI.stop();
      if(req.result){
        this.settings = req.Data;
        this.LoadServiceLayerConfig();
      } else {
        this.alertService.errorAlert(`Error: ${req.errorInfo.Message}`);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorAlert(`Error: ${error}`);
    });
  }
  ResetSchedulingSettingsForm():void{
    this.SchedulingSettingsForm = this.fb.group({
      GoogleServiceAccount: ["", [Validators.required]],
      GoogleServiceAccountPrivateKey: ["",[Validators.required]],
      OutlookServiceUrl:["", [Validators.required]]
    });
  }

  OpenSchedulingSettingsModal(content: NgbModalRef): void {
    let setting: ISetting = this.settings.find(x => x.Code == 'Scheduling');

    if(setting){
      this.SchedulingSettingsForm.patchValue(JSON.parse(setting.Json));
    }
    this.SchedulingSettingsModal = this.modalService.open(
      content,
      { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'Modal-sm' }
    );
  }

  CloseSchedulingModal():void{
    this.SchedulingSettingsModal.close();
  }



  SaveSchedulingSettings(): void {
    this.blockUI.start("Guardando la configuración de eventos...")
    let setting: ISetting = this.settings.find(x => x.Code == 'Scheduling');
    if(setting){
      setting.Json = JSON.stringify(this.SchedulingSettingsForm.value as ISchedulingSetting);
    } else {
      setting = {
        Id: -1,
        Code: 'Scheduling',
        View: 'Calendar',
        Json: JSON.stringify(this.SchedulingSettingsForm.value as ISchedulingSetting)
      }
    }

    this.companyService.StoreSetting(setting).subscribe(req => {
      this.blockUI.stop();
      if(req.result){
        this.CloseSchedulingModal();
        this.alertService.successAlert('Configuración de calendarización guardada exitosamente!');
      } else {
        this.alertService.errorAlert(`Error: ${req.errorInfo.Message}`);
      }
    }, error => {
      this.blockUI.stop();
      this.alertService.errorAlert(`Error: ${error}`);
    });
  }

  SaveServiceLayerSettings(): void{
    let setting: ISetting = this.settings.find(x => x.Code === "useServiceLayer");

    let keyValue: SettingKeyValue<boolean> = { Key: "useServiceLayer", Value: this.useServiceLayer.value };

    if(setting)
    {
      setting.Json = JSON.stringify(keyValue);
    }
    else
    {
      setting = {
        Id: -1,
        Code: 'useServiceLayer',
        View: 'Company',
        Json: JSON.stringify(keyValue)
      }
    }

    this.companyService.StoreSetting(setting).subscribe(req => {
      if(!req.result)
      {
        this.alertService.errorAlert(`Error: ${req.errorInfo.Message}`);
      }
    }, error => {

      this.alertService.errorAlert(`Error: ${error}`);

    });
  }

  LoadServiceLayerConfig(): void{
    this.useServiceLayer = new FormControl();

    let setting: ISetting = this.settings.find(x => x.Code === "useServiceLayer");

    if(setting)
    {
      let keyValue: SettingKeyValue<boolean> = JSON.parse(setting.Json);

      this.useServiceLayer.setValue(keyValue.Value);
    }
    else
    {
      this.useServiceLayer.patchValue(false);
    }
  }


  OpenValidateInvSettingModal(content: NgbModalRef): void {
    let setting: ISetting = this.settings.find(x => x.Code == 'ValidateInventory');

    if(setting){
      let DocsToValidate: string[] = JSON.parse(setting.Json);

      for (const control in this.ValidateInvSettingForm.controls) {
        if(DocsToValidate.some(doc => doc == control))
        {
          this.ValidateInvSettingForm.controls[control].setValue(true);
        }
      }
    }
    this.ValidateInvSettingModal = this.modalService.open(
      content,
      { ariaLabelledBy: 'modal-basic-title', size: 'lg', windowClass: 'Modal-sm' }
    );
  }

  CloseValidateInvSettingModal():void{
    this.ValidateInvSettingModal.close();
  }

  SaveValidateInvSetting(): void
  {
    this.blockUI.start("Guardando la configuración de validación de inventario...");

    let setting: ISetting = this.settings.find(x => x.Code == "ValidateInventory");

    let DocsToValidate: string[] =[];

    for (const control in this.ValidateInvSettingForm.controls) {
      if(this.ValidateInvSettingForm.controls[control].value)
      {
        DocsToValidate.push(control);
      }
    }

    if(setting)
    {
      setting.Json = JSON.stringify(DocsToValidate);
    }
    else
    {
      setting = {
        Id: -1,
        Code: 'ValidateInventory',
        View: 'Company',
        Json: JSON.stringify(DocsToValidate)
      }
    }

    this.companyService.StoreSetting(setting).pipe(finalize(() => this.blockUI.stop())).subscribe({
      next: (callback: BaseResponse) => {
        this.CloseValidateInvSettingModal();
        if(!callback.result)
        {
          this.alertService.errorAlert(`Error: ${callback.errorInfo.Message}`);
        }
      },
      error: (error: any) => {
        this.alertService.errorAlert(`Error: ${error}`);
      }
    });
  }
}




