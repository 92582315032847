import { Component, OnInit, OnDestroy } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

// MODELOS
import {
  RouteLinesMobileModel, CheckInRouteMobileModel, WaypointChecksResponse,
  RoutesAssignStatusResponse, RoutesMobileWithStatus, GlobalStringModel, UserRouteModel, UsersResponse, RoutesMobileModel,
  BaseResponse, RouteAdministrators, frequencyModelRes, UsersModel
} from '../../../models/index';
import { Globals } from './../../../globals';

// RUTAS

// COMPONENTES

// SERVICIOS
import { RouteService, AlertService, UserService, StorageService, FrequencyService } from '../../../services/index';
import { CheckType } from 'src/app/enums';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CalculationType, Geoconfigs } from 'src/app/enums/enums';
import { DatePipe } from '@angular/common';
import { WayPoint } from 'src/app/models/route';
import { GoogleLoadScriptService } from 'src/app/services/google-load-script.service';
import { CommonService } from '../../../services/common.service';
import { IProcessedRoute } from '../../../models/i-processed-route';
import { debounceTime, distinctUntilChanged, finalize, map } from 'rxjs/operators';
import { RouteCalculationService } from '../../../services/route-calculation.service';
import { Observable, Subject } from 'src/app/common/angular.index';
import { OperatorFunction } from 'rxjs';

@Component({
  selector: 'app-list-route',
  templateUrl: './list-route.component.html',
  styleUrls: ['./list-route.component.css'],
  providers: [DatePipe]
})
export class ListRouteComponent implements OnInit, OnDestroy {
  //VARBOX
  estimatingCounter: number = 0;
  private estimating$ = new Subject<number>();
  processedRoutes: IProcessedRoute [] = [];
  global: any;
  files: any[] = [];
  routeFileForm: FormGroup;
  estimatedDetails: any[]; // Falta mapear este objeto, es un objeto resultado de google maps distance matrix, pero con campos custom de origen y destino

  // instancia del block ui
  @BlockUI() blockUI: NgBlockUI;

  title: string;
  routeAssignList: RoutesMobileWithStatus[] = [];

  /* VARIABLES DE LA MODAL DE WAYPOINTS Y CHECKS */
  closeResult: string; // resultado de cerrar la modal
  modalReference: any; // instancia de la modal de creacion o edicion de numeracion
  wayPointsList: WayPoint[] = []; // lista con los waypoints de la ruta selecionada (lineas de la ruta)
  checksList: any[] = []; // lista con los checks de la ruta seleccionada
  iconCheckin: any;
  iconWayPoint: any;
  iconSuccess: any;
  iconFail: any;
  iconCheckAuto: any;
  iconCheckCancel: any;
  iconCheckOpen: any;
  iconCheckStart: any;
  iconCheckFinish: any;

  mapZoom: number;
  lat: number;
  lng: number;
  seeMap: boolean; // para reconcer si mostrar el mapa o la vista de las rutas
  iconsColors: GlobalStringModel[] = []; // lista con los colores de los iconos
  iconsUrl: any[] = [];
  G_CreateRoute: boolean; // permiso para la creacion de rutas
  G_EditRoute: boolean; // permiso para la edicion de rutas
  G_CreateRouteAssign: boolean; // permiso para la creacion de asignacion de rutas
  G_DeleteRouteAssign: boolean; // permiso para la edicion de asignacion de rutas
  G_CloseRoute: boolean; // permiso para cierre de rutas
  assignForm: FormGroup; // formulario de asignacion de rutas
  userList: UserRouteModel[] = []; // lista de usuarios
  routeList: RoutesMobileModel[] = []; // lista de usuarios
  Action: string; // accion del boton de crear o modificar companñia
  userMappId: number; // id de asignacion del usuario seleccionado par la creacion de la ruta
  routeId: number; // id de la ruta para crear la asignacion
  routeInVisualization: string;
  routeAdministrators: UserRouteModel[];
  routeInEdition: RoutesMobileWithStatus;
  isMobile: boolean;
  map: any;
  frequencies: frequencyModelRes[];
  checkSearchForm: FormGroup;
  routeAssignId: number;
  checkImageAsBase64: string;
  checkComments: string;
  images: string[];
  checkFilters: CheckFilter[];
  checkTypesForm: FormGroup;
  dateFromSearchChecks: Date;
  dateToSearchChecks: Date;
  totalPages: number;
  currentPage: number;
  rowsByPage: number;
  visibleRoutesList: RoutesMobileWithStatus[];
  usersAssignedToRoute: UsersModel[];
  routeSearchForm: FormGroup;
  routeAdminType: number;
  activeRoute: RoutesMobileWithStatus;
  userNamesList: string[];
  frequenciesNames: string[];

  constructor(private routeService: RouteService,
    private alertService: AlertService,
    private router: Router,
    private globals: Globals,
    private fb: FormBuilder,
    private userService: UserService,
    private modalService: NgbModal,
    private deviceService: DeviceDetectorService,
    private storage: StorageService,
    private freqService: FrequencyService,
    private datePipe: DatePipe,
    private loadScriptService: GoogleLoadScriptService,
    private commonService: CommonService,
    private routeCalculationService: RouteCalculationService
  ) { 
    
  }

  ngOnInit() {
    this.loadScriptService.LoadScript();
    this.G_CreateRoute = this.globals.generalWebPermList.some(x => x.Name === 'G_CreateRoute');
    this.G_EditRoute = this.globals.generalWebPermList.some(x => x.Name === 'G_EditRoute');
    this.G_CreateRouteAssign = this.globals.generalWebPermList.some(x => x.Name === 'G_CreateRouteAssign');
    this.G_DeleteRouteAssign = this.globals.generalWebPermList.some(x => x.Name === 'G_DeleteRouteAssign');
    this.G_CloseRoute = this.globals.generalWebPermList.some(x => x.Name === 'G_CloseRoute');
    this.isMobile = this.deviceService.isMobile();
    this.initializePage();
  }

  initializePage() {
    this.totalPages = 1;
    this.currentPage = 1;
    let currentDate = new Date();
    this.routeId = 0;
    this.images = [];
    this.rowsByPage = 5;
    this.userMappId = Number(this.storage.get('currentUser').userMappId);
    this.seeMap = false;
    this.title = 'Visualización de rutas';
    this.lat = 9.377837;
    this.lng = -83.707081;
    this.mapZoom = 8;
    this.usersAssignedToRoute = [];
    this.routeAdminType = this.userService.getRouteAdminType();
    this.checkSearchForm = this.fb.group({
      user: [''],
      customer: [{ value: '0', disabled: false }, Validators.required],
      dateFrom: [{ value: this.datePipe.transform(new Date().setMonth(currentDate.getMonth() - 1), 'yyyy-MM-dd'), disabled: false }, Validators.required],
      dateTo: [{ value: this.datePipe.transform(currentDate, 'yyyy-MM-dd'), disabled: false }, Validators.required]
    });
    this.assignForm = this.setAssignForm();
    this.checkTypesForm = this.fb.group({
      all: [true],
      wayPoint: [true],
      checkOutFail: [true],
      checkOutSuccess: [true],
      checkAuto: [true],
      checkCancel: [true],
      checkIn: [true],
      checkOpen: [true],
      checkStart: [true],
      checkFinish: [true]
    });
    this.routeSearchForm = this.fb.group({
      name: [''],
      frequencyId: ['', Validators.compose([Validators.pattern(/^[0-9]+\s-\s[\w\W]{1,}$/g)])],
      type: ['0', Validators.required],
      assignedUserId: ['', Validators.compose([Validators.pattern(/^[0-9]+\s-\s[\w\W]{1,}/g)])]
    });
    this.setIcons();
    this.loadChecksFilters();
    this.getRoutesWithStatus();
    this.getUsersMobile();
    this.getAllAdministrators();
    this.updateRouteSearchFormByLoggedUser();
    //Acciones al finalizar estimaciones
    this.estimating$.subscribe(counter => {
      this.estimatingCounter += counter;
      if(this.processedRoutes.length > 0){
        if(this.estimatingCounter >= this.processedRoutes.length){
          this.blockUI.stop();
          this.CreateRoutes();
        }
      }
    });
  }

  loadChecksFilters() {
    this.checkFilters = [];
    let checkDestination: CheckFilter = {
      Id: 5,
      CanBeDeleted: true,
      DisplayName: 'Destino',
      Name: 'wayPoint',
      Active: true,
      Color: '#52bbe1',
      UseIcon: true
    };

    let checkOutFail: CheckFilter = {
      Id: 0,
      CanBeDeleted: true,
      DisplayName: 'Salida fallida',
      Name: 'checkOutFail',
      Active: true,
      Color: '#f00000',
      UseIcon: true
    };


    let checkOutSuccess: CheckFilter = {
      Id: 1,
      CanBeDeleted: true,
      DisplayName: 'Salida exitosa',
      Name: 'checkOutSuccess',
      Active: true,
      Color: '#95cc26',
      UseIcon: true
    };

    let checkAuto: CheckFilter = {
      Id: 2,
      CanBeDeleted: true,
      DisplayName: 'Punto automático',
      Name: 'checkAuto',
      Active: true,
      Color: '#000',
      UseIcon: true
    };

    let checkCancel: CheckFilter = {
      Id: 3,
      CanBeDeleted: true,
      DisplayName: 'Cancelado',
      Name: 'checkCancel',
      Active: true,
      Color: '#e2e424',
      UseIcon: true
    };

    let checkIn: CheckFilter = {
      Id: 4,
      CanBeDeleted: true,
      DisplayName: 'Llegada',
      Name: 'checkIn',
      Active: true,
      Color: '#e830ce',
      UseIcon: true
    };

    let checkOpen: CheckFilter = {
      Id: 7,
      CanBeDeleted: true,
      DisplayName: 'No ligado a ruta',
      Name: 'checkOpen',
      Active: true,
      Color: '#e89f30',
      UseIcon: true
    };

    let checkStart: CheckFilter = {
      Id: 8,
      CanBeDeleted: true,
      DisplayName: 'Inicio de ruta',
      Name: 'checkStart',
      Active: true,
      Color: '#f1609c',
      UseIcon: true
    };

    let checkFinish: CheckFilter = {
      Id: 9,
      CanBeDeleted: true,
      DisplayName: 'Fin de ruta',
      Name: 'checkFinish',
      Active: true,
      Color: '#f1609c',
      UseIcon: true
    };

    let fromDate: CheckFilter = {
      Id: 10,
      Active: true,
      CanBeDeleted: false,
      Name: '',
      DisplayName: `Desde: ${this.datePipe.transform(new Date(), 'yyyy-MM-dd')}`,
      Color: '',
      UseIcon: false
    }

    let toDate: CheckFilter = {
      Id: 11,
      Active: true,
      CanBeDeleted: false,
      Name: '',
      DisplayName: `Hasta: ${this.datePipe.transform(new Date(), 'yyyy-MM-dd')}`,
      Color: '',
      UseIcon: false
    }

    this.checkFilters.push(checkDestination, checkOutFail, checkOutSuccess, checkAuto, checkCancel, checkIn, checkOpen, checkStart, checkFinish, fromDate, toDate);
  }

  // funcion para obtener los iconos de los checks
  setIcons() {

    this.iconsColors = [
      {
        Id: '5',
        Value1: './../../../../assets/img/marker-blue.png',
        Value2: 'Destino'
      },
      {
        Id: '0',
        Value1: './../../../../assets/img/marker-red.png',
        Value2: 'Salida fallida'
      },
      {
        Id: '1',
        Value1: './../../../../assets/img/marker-green.png',
        Value2: 'Salida exitosa'
      },
      {
        Id: '2',
        Value1: './../../../../assets/img/marker-gray.png',
        Value2: 'Punto automático'
      },
      {
        Id: '3',
        Value1: './../../../../assets/img/marker-yellow.png',
        Value2: 'Cancelado'
      },
      {
        Id: '4',
        Value1: './../../../../assets/img/marker-purple.png',
        Value2: 'Llegada'
      },
      {
        Id: '7',
        Value1: './../../../../assets/img/marker-orange.png',
        Value2: 'No ligado a ruta'
      },
      {
        Id: '8',
        Value1: './../../../../assets/img/marker-pink.png',
        Value2: 'Inicio de ruta'
      },
      {
        Id: '9',
        Value1: './../../../../assets/img/marker-pink.png',
        Value2: 'Fin de ruta'
      }
    ];

    this.iconWayPoint = {
      url: './../../../../assets/img/marker-blue.png'
    };
    this.iconFail = {
      url: './../../../../assets/img/marker-red.png'
    };
    this.iconSuccess = {
      url: './../../../../assets/img/marker-green.png'
    };
    this.iconCheckAuto = {
      url: './../../../../assets/img/marker-gray.png'
    };
    this.iconCheckCancel = {
      url: './../../../../assets/img/marker-yellow.png'
    };
    this.iconCheckin = {
      url: './../../../../assets/img/marker-purple.png'
    };
    this.iconCheckOpen = {
      url: './../../../../assets/img/marker-orange.png'
    };
    this.iconCheckStart = {
      url: './../../../../assets/img/marker-pink.png'
    };
    this.iconCheckFinish = {
      url: './../../../../assets/img/marker-pink.png'
    };
  }

  // funcion para obtener una lista de asignaciones
  async getRoutesWithStatus() {
    this.routeAssignList.length = 0;
    this.blockUI.start();

    let freqResponse = await this.freqService.GetFrequencyList().toPromise();
    if (freqResponse && freqResponse.result) {
      this.frequencies = freqResponse.frequencyList;
      this.frequenciesNames = this.frequencies.map(f => `${f.Id} - ${f.Description}`);
    }

    this.routeService.GetRoutesWithStatus(this.routeAdminType).subscribe((data: RoutesAssignStatusResponse) => {
      if (data.result) {
        this.routeAssignList = data.routeList;
        // this.CalculatePaginationData();
        this.PageChange();
      } else {
        this.alertService.warningAlert(`${data.errorInfo.Message}`);
      }
      this.blockUI.stop();

    }, (error: any) => {
      this.blockUI.stop();
      this.alertService.errorAlert(`${error}`);
      console.log(error);
    });
  }

  // funcion para ir a visualizar el detalle de la ruta y sus checkins
  viewDetailRoute(route: RoutesMobileWithStatus) {
    this.activeRoute = route;
    this.routeInVisualization = route.Name;
    this.wayPointsList.length = 0;
    this.checksList.length = 0;
    this.routeAssignId = route.RouteAssignId;
    this.routeId = route.Id;
    this.dateFromSearchChecks = route.CreationDate;
    this.dateToSearchChecks = route.LastDownload ? route.LastDownload : new Date();

    this.updateDateValuesInRouteDetail(this.dateFromSearchChecks, this.dateToSearchChecks);

    if (route.IsAssigned) {
      this.blockUI.start();
      this.routeService.GetRouteCalculationDetails(route.Id).subscribe(req => {
        if(req.result){
          let details = req.Data.filter(x => x.Type == CalculationType.ESTIMATED)[0];
          if(details){
            this.estimatedDetails = JSON.parse(details.JsonApi);
          }
        }
        this.routeService.GetWaypointChecks(route.Id, this.checkSearchForm.value.customer, '0', this.dateFromSearchChecks, route.LastDownload).subscribe((data: WaypointChecksResponse) => {
          this.blockUI.stop();
          if (data.result) {
            this.wayPointsList = data.wayPointsList;
            this.checksList = data.checksList;
            this.seeMap = true;
            this.getUsersAssignedToRoute(this.routeId, route.UserAssignName);
          } else {
            this.alertService.warningAlert(`${data.errorInfo.Message}`);
            this.seeMap = false;
          }
        }, (error: any) => {
          this.seeMap = false;
          this.blockUI.stop();
          this.alertService.errorAlert(`${error}`);
          console.log(error);
        });
      }, error => {
        this.alertService.errorAlert(error);
      });
    } else {
      this.alertService.warningAlert(`Error la ruta no esta asignada a un usuario`);
    }

  }

  backToList() {
    this.initializePage();
  }

  EditCreateRoute(id: number) {
    this.router.navigate(['createRoute/' + id]);
  }

  markerClicked(infoWindow, gm) {

    if (gm.lastOpen) {
      gm.lastOpen.close();
    }

    if (infoWindow) {
      gm.lastOpen = infoWindow;
      infoWindow.open();
    }

  }

  zoomToMarker(line: RouteLinesMobileModel) {
    this.lat = Number(line.Latitude);
    this.lng = Number(line.Longitude);
    this.map.setCenter({ lat: this.lat, lng: this.lng });

    const zoomInterval = setInterval(() => {
      this.mapZoom++;
      if (this.mapZoom === 20) {
        clearInterval(zoomInterval);
      }
    }, 200);

  }

  //#region ASIGNACIONES

  // convenience getter for easy access to form fields
  get fAssign() { return this.assignForm.controls; }

  // funcion que crea el form de asignacion de ruta
  setAssignForm() {
    return this.fb.group({
      user: ['', Validators.compose([Validators.required,Validators.pattern(/^[0-9]+\s-\s[\w\W]{1,}$/g)])]
    });
  }

  // funcion para obtener una lista de usuarios segun la compañía seleccionada
  getUsersMobile() {
    this.blockUI.start();
    this.userService.getUsersMobile()
      .subscribe((data: UsersResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.userList = data.userList.filter(u => u.Active);
          this.userNamesList = this.userList.map(u => `${u.userMappId} - ${u.userName}`);
          //this.assignForm.patchValue({ user: data.userList[0].userMappId });
        } else {
          this.alertService.warningAlert(`${data.errorInfo.Message}`);
        }
      }, (error) => {
        this.blockUI.stop();
        this.alertService.errorAlert(`${error}`);
        console.log(error);
      });
  }

  // metodo para la edicion de asignacion de rutas
  // metodo para la creacion de asignacion de rutas
  openAssignModal(contentAssign, routeId: number) {
    this.Action = 'Crear';
    this.routeId = routeId;
    this.openModal(contentAssign);
  }

  openModal(contentAssign) {
    this.modalReference = this.modalService.open(contentAssign, { ariaLabelledBy: 'modal-basic-title', centered: true });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.getRoutesWithStatus();
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.getRoutesWithStatus();
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // funcion para cerrar la modal para la creacion o edicion de numeracion
  close() {
    this.modalReference.close();
  }

  // funcion para crear la asignacion de usuarios
  onSubmitCreateAssign() {

    if (!this.assignForm.valid) {
      this.alertService.warningAlert('Formulario invalido');
      return;
    }

    let pattern: RegExp = /^[0-9]+$/g;

    let userMappId: string = this.assignForm.value.user.split('-')[0].trim();

    if (!pattern.test(userMappId)) {
      this.alertService.errorAlert("El usuario ingresadó es incorrecto.");
      return;
    };

    this.blockUI.start("Este proceso puede tardar, espere por favor...");

    this.routeService.CreateAssignRoute(this.assignForm, this.routeId)
      .subscribe((data: BaseResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.alertService.successInfoAlert('Proceso finalizado exitosamente');
          this.close();
        } else {
          this.close();
          this.alertService.warningAlert(`${data.errorInfo.Message}`);
          console.log(data);
        }
      }, (error) => {
        this.blockUI.stop();
        this.close();
        this.alertService.errorAlert(`${error}`);
        console.log(error);
      });
  }

  // funcion para eliminar una asignacion de la ruta
  async deleteAssign(AssignId: number) {
    let result = await this.alertService.confirmationAlert(`Confirmación`, '¿Eliminar la asignación de ruta?', 'Eliminar');

    if (result) {
      this.blockUI.start('Este proceso puede tardar, espere por favor...');
      this.routeService.DeleteAssignRoute(AssignId).subscribe((data: BaseResponse) => {
        this.blockUI.stop();
        if (data.result) {
          this.getRoutesWithStatus();
          this.alertService.successInfoAlert('Proceso finalizado exitosamente');
        } else {
          this.alertService.warningAlert(`${data.errorInfo.Message}`);
        }
      }, (error) => {
        this.blockUI.stop();
        this.alertService.errorAlert(`${error}`);
        console.log(error);
      });

    }
  }
  //#endregion

  getChecksByCustomer(customer: RouteLinesMobileModel) {
    return this.checksList.filter(x => x.CardCode === customer.CardCode && x.CheckType != CheckType.CheckAuto)
      .sort((x, y) => (x.MobileCreationDate > y.MobileCreationDate) ? 1 : -1);
  }

  getRouteLineNameByCheck(check: CheckInRouteMobileModel) {
    let routeLine = this.wayPointsList.find(x => x.Id === check.RouteLineId);
    return routeLine ? routeLine.Address : '';
  }

  getCheckImageSrc(checkType: number) {
    let icon = this.iconsColors.find(x => x.Id == checkType.toString());
    return icon ? icon.Value1 : '';
  }

  getRouteAdminsNames(route: RoutesMobileWithStatus) {
    if (!route.Administrators || route.Administrators.length === 0) return 'Sin administradores';

    let administrators = route.Administrators[0].userName;
    if (route.Administrators.length > 1) administrators += ` y ${route.Administrators.length - 1} más`;

    return administrators;
  }

  getAllAdministrators() {
    this.userService.getRouteAdministrators().subscribe((data: RouteAdministrators) => {
      if (data.result) {
       
        this.routeAdministrators = data.Administrators;
      } else {
        this.alertService.warningInfoAlert(data.errorInfo.Message);
      }
    }, err => {
      console.log(err);
      this.alertService.warningInfoAlert(err);
    });
  }

  getUnasignedAdminsByRoute() {
    let filteredList: UserRouteModel[] = [];
    if (!this.routeInEdition.Administrators || this.routeInEdition.Administrators.length === 0) return this.routeAdministrators;
   
    this.routeAdministrators.forEach(x => {
      if (!this.routeInEdition.Administrators.some(y => y.userMappId === x.userMappId)
        && (x.RouteAdminType == Geoconfigs.RouteAdmin || x.RouteAdminType == this.routeInEdition.Type)) {
        if(!filteredList.some(a => a.userMappId === x.userMappId)) filteredList.push(x);
      }
    });
   
    return filteredList;
  }

  addAdminToRoute(newAdmin: UserRouteModel) {
    this.routeInEdition.Administrators.push({ ...newAdmin });
  }

  onClickRouteAdminsModal(adminsModal: any, route: RoutesMobileWithStatus) {
    this.routeInEdition = route;
    let modal = this.modalService.open(adminsModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' });
    modal.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  unasignAdminFromRoute(adminIndex: number) {
    if (this.routeInEdition.Administrators.length === 1) {
      this.alertService.infoInfoAlert('La ruta debe poseer al menos un administrador');
      return;
    }

    this.routeInEdition.Administrators.splice(adminIndex, 1);
  }

  onClickPutRouteAdministrators() {
    this.blockUI.start();
    this.routeService.putRouteAdministrators(this.routeInEdition).subscribe((data: BaseResponse) => {
      this.blockUI.stop();
      this.routeInEdition = undefined;
      if (data.result) {
        this.alertService.successInfoAlert('Proceso finalizado exitosamente');
      } else {
        this.alertService.warningAlert(data.errorInfo.Message);
      }
    }, err => {
      this.blockUI.stop();
      console.log(err);
      this.alertService.errorAlert(err);
    });
  }

  loggedUserIsAdministrator(route: RoutesMobileWithStatus) {
    if (!route.Administrators || route.Administrators.length === 0) return true;

    return route.Administrators.some(x => x.userMappId == this.userMappId);
  }

  mapReady(map) {
    this.map = map;
    // let maps = new google.maps.DistanceMatrixService();
    // maps.getDistanceMatrix();
  }

  getFrequencyNameById(freqId: number) {
    if (!this.frequencies || this.frequencies.length === 0 || !freqId || freqId === 0) return 'Sin frecuencia';

    return this.frequencies.find(x => x.Id == freqId).Description;
  }

  get searchForm() { return this.checkSearchForm.controls; }

  onClickCheckDetails(checkDetailModal: any, check: CheckInRouteMobileModel) {
    this.blockUI.start();
    this.routeService.DownloadBlob(check.Photos).subscribe(response => {
      this.blockUI.stop();
      if (response.result) {
        this.checkImageAsBase64 = `data:image/jpeg;base64,${response.Base64Image}`;
        this.images.push(this.checkImageAsBase64);
      } else {
        console.log(response.errorInfo.Message);
      }
      this.checkComments = check.Comments;
      this.modalService.open(checkDetailModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' });
    }, error => {
      this.blockUI.stop();
      this.alertService.errorAlert(error);
    });
  }

  onClickDismissModal() {
    this.modalService.dismissAll();
    this.images = [];
    this.checkComments = '';
  }

  loggedUserIsAssigned(route: RoutesMobileWithStatus) {
    if (!route.AssignedUserId) return false;

    let loggedUserId = JSON.parse(this.storage.getCurrentSession()).userMappId;
    if (!loggedUserId) return false;

    return loggedUserId == route.AssignedUserId;
  }

  getActiveChecksFilters() {
    return this.checkFilters.filter(x => x.Active);
  }

  openCheckFiltersModal(checkFiltersModal) {
    this.modalService.open(checkFiltersModal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' });
  }

  onClickCheckTypeFilter(checkType: number, formControlName: string) {
    if (checkType === -1) {
      let newValue = this.checkTypesForm.get('all').value;
      newValue = !newValue;

      this.checkTypesForm.get('all').setValue(newValue);

      for (let control in this.checkTypesForm.controls) {
        this.checkTypesForm.get(control).setValue(newValue);
        this.checkTypesForm.get(control).updateValueAndValidity();
      }

      this.checkFilters.forEach(x => {
        if (x.CanBeDeleted) x.Active = newValue;
      });

    } else {
      let checkFilter = this.checkFilters.find(x => x.Id == checkType);

      if (checkFilter && checkFilter.CanBeDeleted) {
        checkFilter.Active = !checkFilter.Active;
        this.checkTypesForm.get(formControlName).setValue(checkFilter.Active);
      }

      this.checkTypesForm.get('all').setValue(!(this.checkFilters.some(x => x.CanBeDeleted && !x.Active)));
    }
  }

  isCheckTypeVisible(checkType: number) {
    if (!this.checkFilters) return false;

    let checkFilter = this.checkFilters.find(x => x.Id == checkType);
    if (!checkFilter) return false;

    return checkFilter.Active;
  }

  getChecksByType(checkType: number) {
    if (!this.checksList || this.checksList.length === 0) return [];

    return this.checksList.filter(x => x.CheckType === checkType);
  }

  updateDateValuesInRouteDetail(dateFrom: Date, dateTo: Date) {

    let dateFromFilter = this.checkFilters.find(x => x.Id === 10);
    dateFromFilter.DisplayName = `Desde: ${this.datePipe.transform(dateFrom, 'short')}`;

    let dateToFilter = this.checkFilters.find(x => x.Id === 11);
    dateToFilter.DisplayName = `Hasta: ${this.datePipe.transform(dateTo, 'short')}`;

  }

  onClickFilterChecks() {
    this.blockUI.start();
    this.routeService.GetWaypointChecks(this.routeId, this.checkSearchForm.value.customer, this.checkSearchForm.value.user, this.checkSearchForm.value.dateFrom, this.checkSearchForm.value.dateTo).subscribe((data: WaypointChecksResponse) => {
      this.blockUI.stop();
      this.onClickDismissModal();
      if (data.result) {
        this.wayPointsList = data.wayPointsList;
        this.checksList = data.checksList;
        this.updateDateValuesInRouteDetail(this.checkSearchForm.value.dateFrom, this.checkSearchForm.value.dateTo);
      } else {
        this.alertService.warningAlert(`${data.errorInfo.Message}`);
      }
    }, (error: any) => {
      this.blockUI.stop();
      this.onClickDismissModal();
      this.alertService.errorAlert(`${error}`);
      console.log(error);
    });
  }

  PageChange() {
    this.onClickSearchRoutesFilter()
    //this.visibleRoutesList = this.routeAssignList.slice((this.currentPage - 1) * this.rowsByPage, this.currentPage * this.rowsByPage);
  }

  CalculatePaginationData() {
    this.currentPage = 1;
    this.totalPages = Math.ceil(this.routeAssignList.length / this.rowsByPage) * 10;
  }

  onChangeRowsByPage($event) {
    //this.rowsByPage = Number($event.target.value);
    //this.CalculatePaginationData();
    this.currentPage = 1;
    this.onClickSearchRoutesFilter();
  }

  getUsersAssignedToRoute(routeId: number, assignedUserName: string) {
    this.blockUI.start("Procesando...");
    this.routeService.GetUsersAssignedToRoute(routeId).subscribe(data => {
      this.blockUI.stop();
      if (data && data.result) {
        this.usersAssignedToRoute = data.Users;
        this.usersAssignedToRoute.forEach(x => {
          if (x.userName === assignedUserName) {
            this.checkSearchForm.get('user').setValue(x.userEmail);
          }
        });
      } else {
        this.alertService.warningAlert(data.errorInfo.Message);
      }
    }, err => {
      this.blockUI.stop();
      this.alertService.errorAlert(err);
    });
  }

  updateRouteSearchFormByLoggedUser() {
    if (this.routeAdminType === 0 || this.routeAdminType === 8) this.routeSearchForm.get('type').enable();
    else {
      this.routeSearchForm.get('type').disable();
      this.routeSearchForm.get('type').setValue(this.routeAdminType);
    }

    if (this.routeAdminType === 0) {
      this.routeSearchForm.get('assignedUserId').disable();
      this.routeSearchForm.get('assignedUserId').setValue(this.userList.filter(u => {
        if(u.userMappId === this.userMappId) return `${u.userMappId} - ${u.userName}`;
      }));
    }
  }

  onClickSearchRoutesFilter() {
    // this.routeAssignList.length = 0;
    this.blockUI.start();

    let frequencyId: number = 0;

    let assignedUserId: number =  0;

    if(this.routeSearchForm.value.assignedUserId !== "") assignedUserId = parseInt(this.routeSearchForm.value.assignedUserId.split('-')[0].trim());

    if (this.routeSearchForm.value.frequencyId !== "") frequencyId = parseInt(this.routeSearchForm.value.frequencyId.split('-')[0].trim());

    this.routeService.GetRoutesFilter(
      this.routeSearchForm.get('name').value, 
      this.routeSearchForm.get('type').value, 
      frequencyId, 
      assignedUserId,
      (this.currentPage - 1) * this.rowsByPage,
      this.rowsByPage).subscribe((data) => {
      this.blockUI.stop();

      if (data.result) {
        this.visibleRoutesList = this.routeAssignList = data.Data.RoutesMobileWithStatus;
        this.totalPages = data.Data.TotalCount;
      } else {
       // this.alertService.warningAlert(`${data.errorInfo.Message}`);
       this.alertService.infoInfoAlert(`${data.errorInfo.Message}`);
       this.visibleRoutesList = this.routeAssignList = [];
       this.totalPages = 1;
      }
    }, (error: any) => {
      this.blockUI.stop();
      this.alertService.errorAlert(`${error}`);
      console.log(error);
    });
  }

  groupCustomersInRoute() {
    let filteredList: RouteLinesMobileModel[] = [];

    if (!this.wayPointsList) return [];

    this.wayPointsList.forEach(x => {
      if (!filteredList.some(filtered => filtered.CardCode === x.CardCode)) filteredList.push(x);
    });

    return filteredList;
  }

  getCustomerLocations(customer: RouteLinesMobileModel) {
    return this.wayPointsList.filter(x => x.CardCode === customer.CardCode);
  }

  getChecksByRouteLine(line: RouteLinesMobileModel) {
    return this.checksList.filter(x => x.RouteLineId === line.Id);
  }

  getCheckTypeName(check: CheckInRouteMobileModel) {
    let checkType = this.iconsColors.find(x => x.Id == check.CheckType.toString());
    if (checkType) return checkType.Value2;

    return 'Otros';
  }

  addBounceAnimationToMarker(line: any) {
    if (!line.animation) {
      line.animation = 'BOUNCE';
    }
    else {
      line.animation = null;
    }
  }

  getChecksNotInRouteLine() {
    if (!this.checksList) return [];

    return this.checksList.filter(x => !x.RouteLineId);
  }

  async CloseRoute(routeId: number): Promise<void> {
    let result = await this.alertService.confirmationWithTextAreaAlert(`¿Desea cerrar la ruta?`, 'Indique el motivo del cierre de la ruta', 'Continuar');

    if (result.value) {
      this.blockUI.start('Este proceso puede tardar, espere por favor...');
      this.routeService.CloseRoute(routeId, result.value).subscribe(next => {
        this.blockUI.stop();
        if (next.result) {
          this.getRoutesWithStatus();
          this.alertService.successInfoAlert('Proceso finalizado exitosamente');
        } else {
          this.alertService.warningAlert(`${next.errorInfo.Message}`);
        }
      }, (error) => {
        this.blockUI.stop();
        this.alertService.errorAlert(`${error}`);
      });
    } else if(!result.dismiss){
      this.alertService.infoAlert('Debe indicar un motivo para cerrar una ruta');
    }
  }

  GetEstimatedDistance(location: WayPoint): number{
    if(this.estimatedDetails){
      let detail = this.estimatedDetails.find(
        x => 
        x.destinationLine.address === location.Address && 
        x.destinationLine.latitude == location.Latitude &&
        x.destinationLine.longitude == location.Longitude);
      if(detail) return detail.rows[0].elements[0].distance.value;
    }
    return 0
  }

  GetEstimatedDuration(location: WayPoint): number{
    if(this.estimatedDetails){
      let detail = this.estimatedDetails.find(
        x => 
        x.destinationLine.address === location.Address && 
        x.destinationLine.latitude == location.Latitude &&
        x.destinationLine.longitude == location.Longitude);
      if(detail) return detail.rows[0].elements[0].duration.value;
    } 
    return 0
  }

  OpenRoutesUploadModal(modal: any) {
    try{
      let modalRef = this.modalService.open(modal, { ariaLabelledBy: 'modal-basic-title', centered: true, size: 'lg' });
      modalRef.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    } catch(exception){
      console.log(exception);
    }
  }

  DownloadRouteMassiveTemplateFile(){
    try{
      this.blockUI.start('Descargando plantilla...');
      this.routeService.DownloadRouteMassiveTemplateFile().pipe(finalize(() => this.blockUI.stop())).subscribe(response => {
        if(response.result){
          this.commonService.downloadFile(response.Data.Base64, response.Data.Name, response.Data.Type, 'xlsx');
        } else {
          this.alertService.errorAlert(response.errorInfo.Message);
        }
        
      }, error => {
        this.alertService.errorAlert(error);
      });
    } catch(exception){
      console.log(exception);
    }
    
  }

  FileDropped($event): void {
    try{
      this.PrepareFilesList($event);
    } catch(exception){
      console.log(exception);
    }
  }

  FileBrowseHandler($event): void {
    try{
      console.log($event);
      this.PrepareFilesList($event.srcElement.files);
    } catch(exception){
      console.log(exception);
    }
  }

  DeleteFile(index: number): void {
    try{
      this.files.splice(index, 1);
    } catch(exception){
      console.log(exception);
    }
  }

  PrepareFilesList(files: Array<any>): void {
    try{
      for (const item of files) {
        if(['XLS', 'XLSX', 'CSV'].includes(item.name.split('.').pop().toUpperCase())){
          this.files = [];
          item.progress = 0;
          this.files.push(item);
        } else {
          this.alertService.warningAlert('Solo se permiten archivos .xls, .xlsx o .csv');
        }
      }

      (<HTMLInputElement>document.getElementById('fileDropRef')).value = '';
      this.UploadFilesSimulator(0);
    } catch(exception){
      console.log(exception);
    }
  }

  UploadFilesSimulator(index: number): void {
    try {
      setTimeout(() => {
        if (index === this.files.length) {
          return;
        } else {
          const progressInterval = setInterval(() => {
            if (this.files[index].progress === 100) {
              clearInterval(progressInterval);
              this.UploadFilesSimulator(index + 1);
              this.UploadRoutesFile();
            } else {
              this.files[index].progress += 5;
            }
          }, 10);
        }
      }, 10);
    } catch(exception){
      console.log(exception);
    }
  }

  UploadRoutesFile(): void {
    try {
      const formData = new FormData();
      this.processedRoutes = [];
  
      if(this.files.length > 0){
        formData.append('file', this.files[0] as File);
      }
      
      this.blockUI.start('Subiendo archivo...');
      this.routeService.UploadRoutesFile(formData).pipe(finalize(() => this.blockUI.stop())).subscribe(req => {
        try{
          if(req.result){
            this.processedRoutes = req.Data;
          } else {
            this.alertService.errorAlert(req.errorInfo.Message);
            this.processedRoutes = [];
          }
        } catch(exception){
          console.log(exception);
        }
      }, error => {
        this.alertService.errorAlert(error);
        this.processedRoutes = [];
      });
    } catch(exception){
      console.log(exception);
    }
  }

  async CreateRoutes(): Promise<void>{
    try{
      this.blockUI.start('Creando rutas...');

      let processedRoutesFiltered = this.processedRoutes.filter(r => r.Status);

      this.routeService.CreateRoutes(processedRoutesFiltered).pipe(finalize(() => this.blockUI.stop())).subscribe(req => {
        try{
          if(req.result){
            this.alertService.successInfoAlert("Rutas creadas con éxito!");
            this.files = [];
            this.processedRoutes = req.Data;
          } else {
            this.alertService.errorAlert(req.errorInfo.Message);
          }
        } catch(exception){
          console.log(exception);
        }
      }, error => {
        this.alertService.errorAlert(error);
      });
    } catch(exception){
      console.log(exception);
    }
  }

  EstimateCreteRoutes(){
    try{
      this.blockUI.start('Realizando estimaciones rutas...');
      this.estimatingCounter = 0;
  
      for(let processedRoute of this.processedRoutes){
        if(processedRoute.Estimate){
          this.EstimatedRoute(processedRoute).then(result => {
            this.estimating$.next(1);
          }).catch(error => {
            processedRoute.Result = error;
            this.estimating$.next(1);
          });
        } else {
          this.estimating$.next(1);
        }
      }
    } catch (exceptio){
      console.log(exceptio);
    }
  }

  async EstimatedRoute(processedRoute: IProcessedRoute): Promise<IProcessedRoute> {
    processedRoute.Route.TotalEstimatedDistance = 0;
    processedRoute.Route.TotalEstimatedDuration = 0;
    try{
 
      let result = await this.routeCalculationService.CalculateRoute(processedRoute.Route.V_RouteLines);
  
      result.forEach(matrix => {
        if(matrix.rows){
          matrix.rows.forEach(row => {
            row.elements.forEach(element => {
              if(element.status === 'OK'){
                processedRoute.Route.TotalEstimatedDistance += element.distance.value;
                processedRoute.Route.TotalEstimatedDuration += element.duration.value;
              }
            });
          });
        }
      });
  
      processedRoute.EstimateJson = JSON.stringify(result);
    } 
    catch (exception)
    {
      console.log(exception)
    }
    finally {
      return processedRoute;
    }
  }

  filterUserNames: OperatorFunction<string, string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(120),
      distinctUntilChanged(),
      map(term => term.length < 1 ? []
        : this.userNamesList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  filterFrequenciesNames: OperatorFunction<string, string[]> = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(120),
    distinctUntilChanged(),
    map(term => term.length < 1 ? []
      : this.frequenciesNames.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )

  clearFormControl(control: FormControl){
    if(control.value !== "") control.setValue("");
  }

  ngOnDestroy(){
    this.estimating$.unsubscribe();
  }
}

export interface CheckFilter {
  Id: number;
  CanBeDeleted: boolean;
  Name: string;
  DisplayName: string;
  Active: boolean;
  Color: string;
  UseIcon: boolean;
};